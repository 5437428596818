import { ReactElement, Fragment, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { QuestionnaireDefinition, SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { DebugToolbar } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import Urls from '../../Navigation/Urls';
import { DebugToolbarModalContainer, DebugView } from './DebugToolbarModalContainer';

export type QuestionnaireResponse = {
  version: string;
  questionnaire: QuestionnaireDefinition;
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
};

export function DebugToolbarContainer(): ReactElement | null {
  const navigate = useNavigate();
  const { enableQuestionnaireDebugTools, git } = useContext(CarrierContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [debugView, setDebugView] = useState<DebugView | undefined>(undefined);

  const onCreateNewApplication = useCallback(() => {
    navigate(Urls.new.fullPath);
  }, [navigate]);

  if (!enableQuestionnaireDebugTools) return null;

  return (
    <Fragment>
      <DebugToolbar
        onCreateNewApplication={onCreateNewApplication}
        onLoadPrefillSteps={() => {
          setDebugView(DebugView.stepFill);
          setIsOpen(true);
        }}
        onLoadNavigateSteps={() => {
          setDebugView(DebugView.stepList), setIsOpen(true);
        }}
        commitSha={git.commitSha}
      />
      {isOpen && debugView && <DebugToolbarModalContainer debugView={debugView} onClose={() => setIsOpen(false)} />}
    </Fragment>
  );
}
