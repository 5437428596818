import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authenticationSlice } from '@breathelife/redux';
import { Loader } from '@breathelife/ui-components';

import Urls from '../../Navigation/Urls';
import { insuranceApplicationSlice } from '../../Redux/InsuranceApplication/InsuranceApplicationSlice';
import * as ApplicationService from '../../Services/ApplicationService';
import { seedApplication } from './seedApplication';

export type Props = {
  seedData?: unknown;
  redirectPath?: string;
  appIdKey?: string;
};

export function CreateMockApplicationView(props: Props): ReactElement | null {
  const { redirectPath, appIdKey } = props;
  const applicationData = props.seedData ?? seedApplication;

  const dispatch = useDispatch();
  const [appId, setAppId] = useState<string>('');

  useEffect(() => {
    (async (): Promise<void> => {
      const payload = applicationData as Record<string, unknown>;

      const data = { ...payload, isTest: true };

      // the external flows don't include an application JWT with a nonce
      // which will cause the shared API gateway to throw an error
      const response = await ApplicationService.createApplication(data);

      const { token, application } = response as ApplicationService.QuestionResponse;
      dispatch(insuranceApplicationSlice.actions.reset());
      dispatch(authenticationSlice.actions.setToken({ token }));
      setAppId(application?.id);
      return;
    })();
  }, [dispatch, applicationData]);

  if (appId) {
    let redirectTo = `${Urls.home.fullPath}?id=${appId}`;

    if (redirectPath && appIdKey) {
      redirectTo = `${redirectPath}?${appIdKey}=${appId}`;
    }

    return <Navigate to={redirectTo} replace />;
  }

  return <Loader />;
}
