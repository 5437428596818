import queryString from 'query-string';

export function submitUrlForm(url: string): any {
  const urlData = queryString.parseUrl(url);
  const keys = Object.keys(urlData.query);

  const form = document.createElement('form');
  form.setAttribute('method', 'POST');

  keys.forEach((key) => {
    const input = document.createElement('input');
    input.setAttribute('name', key);
    input.setAttribute('value', urlData.query[key] as string);
    input.setAttribute('type', 'hidden');
    form.appendChild(input);
  });

  form.setAttribute('action', urlData.url);

  const submitButton = document.createElement('input');
  submitButton.setAttribute('type', 'submit');
  submitButton.setAttribute('type', 'hidden');

  form.appendChild(submitButton);

  document.body.appendChild(form);

  form.submit();
}
