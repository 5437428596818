import dayjs from 'dayjs';
import I18n, { ToCurrencyOptions, TranslateOptions } from 'i18n-js';
import _ from 'lodash';
import queryString from 'query-string';

import { DeepPartial, Language, LanguageRegion, Localizable } from '@breathelife/types';
import { LegalLink } from '@breathelife/ui-components';

import baseTranslations, { Dictionaries } from './';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en-CA';

const LanguageRegions: { [lang: string]: LanguageRegion } = {
  [Language.fr]: LanguageRegion.frCa,
  [Language.en]: LanguageRegion.enCa,
};

let carrierLanguages: Language[] = [];

export function initCarrierLanguages(languages: Language[]): void {
  if (!languages.length) throw new Error('Must initialize with at least one language');

  carrierLanguages = languages.filter((language) => language in Language);
}

// TODO: figure out what to do with this, since we can't access the CONFIG object from here
// let langSwitcher = CONFIG.carrier.langSwitcher;
let langSwitcher: Partial<Record<Language, string>> | null = null;

export function setLangSwitcher(newLangSwitcher: Partial<Record<Language, string>>): void {
  langSwitcher = newLangSwitcher;
}

const langBasedOnUrl = (): Language | undefined => {
  if (!langSwitcher) return;
  if (langSwitcher.en && _.includes(window.location.origin, langSwitcher.en)) return Language.fr;
  if (langSwitcher.fr && _.includes(window.location.origin, langSwitcher.fr)) return Language.en;
  throw new Error('Unsupported language in URL!');
};

export function getOtherLocales(lang?: Language): Language[] {
  if (carrierLanguages.length === 1) return [];
  if (!lang) lang = getCurrentLocale();

  return carrierLanguages.filter((language) => language !== lang);
}

export function getCurrentLocale(): Language {
  const queryStringLanguage = queryString.parse(window.location.search).lang;
  const locale =
    Language[queryStringLanguage as keyof typeof Language] ||
    langBasedOnUrl() ||
    window.localStorage.getItem('locale') ||
    window.navigator.userLanguage ||
    window.navigator.language ||
    DEFAULT_LANGUAGE ||
    DEFAULT_LOCALE;

  return locale.split('-')[0] as Language;
}

let currentLocale = getCurrentLocale();

export function setTranslations(carrierTranslations: DeepPartial<Dictionaries>): void {
  I18n.translations = {
    en: _.merge({}, baseTranslations.en, carrierTranslations.en),
    fr: _.merge({}, baseTranslations.fr, carrierTranslations.fr),
  };
}

export function setLocale(locale: string = getCurrentLocale()): void {
  window.localStorage.setItem('locale', locale);
  currentLocale = locale as Language;
  I18n.locale = locale;
}

setLocale(currentLocale);

export const shortLocale = (): Language => (currentLocale.indexOf(Language.fr) !== -1 ? Language.fr : Language.en);

export function getLanguageRegion(): LanguageRegion {
  const language = shortLocale();
  return LanguageRegions[language];
}

I18n.fallbacks = true;
I18n.translations = baseTranslations;
I18n.defaultLocale = DEFAULT_LOCALE;

export function text(value: string, parameters?: TranslateOptions): string {
  return I18n.t(value, parameters);
}

// Returns `undefined` if a text isn't defined instead of using I18n's fallback system
export function textIfExists(value: string, parameters?: { [key: string]: any }): string | undefined {
  return I18n.t(value, { ...parameters, defaults: [{ message: '' }] }) || undefined;
}

export function toCurrency(value: number | null, options: ToCurrencyOptions = {}): string {
  if (value == null) {
    return 'N/A';
  }

  let currencyFormat = '%u%n';
  let delimiter = ',';
  let separator = '.';
  if (shortLocale() === 'fr') {
    currencyFormat = '%n %u';
    delimiter = ' ';
    separator = ',';
  }

  return I18n.toCurrency(value, _.merge(options, { delimiter, separator, format: currencyFormat }));
}

export function getLanguageSwitcherURL(): string | undefined {
  if (langSwitcher) {
    return langSwitcher[shortLocale()];
  }
  return `${window.location.origin}${shortLocale() === 'en' ? '?lang=fr' : '?lang=en'}`;
}

export function toRoundedCurrency(amount: number | null): string {
  return toCurrency(amount, { precision: 0 });
}

export function toNumber(value: number, parameters: any = {}): string {
  return I18n.toNumber(value, parameters);
}

export function formatDate(date: Date | string): string {
  return dayjs(date).format(text('formats.date'));
}

export function formatTime(date: Date | string): string {
  return dayjs(date).format(text('formats.time'));
}

export function formatDatetime(date: Date | string): string {
  return `${formatDate(date)} ${text('formats.at')} ${formatTime(date)}`;
}

export function a11yPageTitle(title: string): string {
  const pageTitle = text(`a11y.pageTitle.${title}`);
  a11yNavigationPageTitle(pageTitle);
  return pageTitle;
}
export function a11yNavigationPageTitle(pageTitle: string): string {
  return text('a11y.navigation', { pageTitle });
}

export function getLegalItems(): LegalLink[] {
  const links: LegalLink[] = [];

  const keys = Object.keys(text('legal.footer'));
  keys.forEach((key: string) => {
    const item = Object.keys(text(`legal.footer.${key}`));
    const description = text(`legal.footer.${key}.text`);

    const legalItem: LegalLink = {
      text: description,
    };

    if (item.includes('link')) {
      legalItem.href = text(`legal.footer.${key}.link`);
    }

    links.push(legalItem);
  });

  return links;
}

export function localize(text: Localizable | undefined, language: Language): string | undefined {
  if (!text) return undefined;
  return text[language];
}

export function getCarrierLanguages(): Language[] {
  return carrierLanguages;
}
