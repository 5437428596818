import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';
import { RunningText, Title, Icon } from '@breathelife/ui-components';

import { text } from '../../Localization/Localizer';

export const StyledTitle = styled(Title)`
  text-align: center;
`;

export const StyledRunningText = styled(RunningText)`
  text-align: center;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  max-width: 272px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: 460px;
  }

  svg {
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      height: 83px;
      width: 83px;
    }
  }
`;

export function ErrorPage(): ReactElement | null {
  return (
    <Container>
      <Content>
        <Icon name={IconName.error404} size='66px' />
        <Box component={StyledTitle} mt={7}>
          {text('errorPage.generic.title')}
        </Box>
        <Box component={StyledRunningText} mt={3}>
          {text('errorPage.generic.subtitle')}
        </Box>
      </Content>
    </Container>
  );
}
