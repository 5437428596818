import _ from 'lodash';

import { Theme } from '@breathelife/types';
import { getColorizedBaseTheme, SharedTheme } from '@breathelife/ui-components';

export function colorizeTheme(theme: Theme): SharedTheme {
  const colorRanges = theme.colorRanges;
  const baseTheme = getColorizedBaseTheme(colorRanges);
  const { primary, secondary, tertiary } = colorRanges;
  const grey = colorRanges.grey ?? baseTheme.colors.grey;

  return _.merge({}, baseTheme, {
    colors: {
      base: {
        secondary: tertiary[40],
        softSecondary: tertiary[10],
      },
      button: {
        base: {
          background: {
            default: primary.default,
            hover: primary[60],
            active: primary[70],
            focus: primary.default,
          },
          border: {
            hover: primary[70],
            active: primary[80],
          },
        },
        scheduleCall: {
          background: {
            default: grey[20],
            hover: grey[0],
            focus: grey[0],
            active: grey[0],
          },
          border: {
            default: grey[40],
            hover: grey[50],
            focus: tertiary[40],
            active: grey[50],
          },
        },
        option: {
          background: {
            active: primary[10],
          },
          border: {
            default: 'transparent',
            hover: 'transparent',
            focus: primary[60],
            active: 'transparent',
          },
          text: {
            default: grey[80],
            title: grey[90],
            selected: primary[60],
          },
        },
      },
      text: {
        regular: grey[80],
      },
      selection: {
        background: {
          default: grey[10],
          active: grey[30],
          selected: primary[10],
          focus: grey[0],
          hover: grey[0],
        },
        border: {
          active: primary[60],
          selected: primary[60],
        },
        text: {
          selected: grey[90],
        },
      },
      grey: {
        default: grey[40],
        border: grey[40],
        hover: grey[20],
        inputPressed: grey[30],
        lighter: grey[10],
        dark: grey[60],
        darker: grey[80],
      },
      input: {
        border: {
          focus: secondary[30],
        },
      },
      shadow: 'rgba(9, 30, 66, 0.25)',
    },
    fonts: {
      family: {
        main: `'Noto Sans', ${baseTheme.fonts.family.fallbacks}`,
        secondary: `'Noto Sans', ${baseTheme.fonts.family.fallbacks}`,
        title: `'Noto Sans', ${baseTheme.fonts.family.fallbacks}`,
        condensed: `'Roboto Condensed', ${baseTheme.fonts.family.fallbacks}`,
      },
      size: {
        smaller: '11px',
        small: '12px',
        regular: '13px',
        large: '16px',
        checkbox: '15px',
        radio: '15px',
        input: '14px',
        title: {
          desktop: '31px',
          tablet: '24px',
          mobile: '19px',
        },
        productPage: {
          default: '14px',
          stepTitle: '21px',
          productTitle: {
            desktop: '18px',
            mobile: '14px',
          },
          productCategory: {
            desktop: '16px',
            mobile: '14px',
          },
        },
        questions: {
          regular: '15px',
        },
        option: {
          title: '14px',
          description: '12px',
        },
        button: '15px',
      },
      weight: {
        regular: 'regular',
        title: 'regular',
        radio: 'bold',
        checkbox: 'bold',
        button: 'bold',
      },
    },
    metrics: {
      borders: {
        default: '1px',
        focus: '0px',
      },
      button: {
        borderRadius: '30px',
      },
      logo: {
        height: '40px',
        mobile: {
          height: '40px',
        },
      },
      input: {
        borderRadius: '3px',
      },
    },
  });
}
