import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { deserializeNodeIdToAnswerPathMap, QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { DEFAULT_TIMEZONE, LeadMarketingMetadata, Timezone, VersionedAnswers } from '@breathelife/types';

import { createQuestionnaireFromSubsection } from '../../Helpers/createQuestionnaireFromSubsection';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { shortLocale } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { startGenericFlow as startFlowOperation } from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import * as stepOperations from '../../Redux/Step/StepOperations';
import { GenericLinkLanding } from './GenericLinkLanding';

export function GenericLinkLandingContainer(): ReactElement | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const lang = shortLocale();

  useEffect(() => {
    dispatch(stepOperations.getLandingStep(lang));
  }, [dispatch, lang]);

  const { currentStep, nodeIdToAnswerPathMap } = useCxSelector((store) => store.consumerFlow.step);
  const isApplicationLoading = useCxSelector((store) => store.consumerFlow.insuranceApplication.isLoading);
  const blueprint = useCxSelector((store) => store.consumerFlow.step.blueprint);
  const applicationContext = useCxSelector(
    (store) => store.consumerFlow.insuranceApplication.insuranceApplication?.applicationContext,
  );
  const questionnaireEngine = useMemo(() => {
    if (!nodeIdToAnswerPathMap || !currentStep || !blueprint) return;
    const timezone = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).withDefault(DEFAULT_TIMEZONE);

    const deserializedNodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(nodeIdToAnswerPathMap);
    const questionnaire = createQuestionnaireFromSubsection(currentStep);
    return new QuestionnaireEngine({
      questionnaire,
      nodeIdToAnswerPathMap: deserializedNodeIdToAnswerPathMap,
      blueprint,
      timezone,
      applicationContext,
    });
  }, [nodeIdToAnswerPathMap, blueprint, currentStep, applicationContext]);

  const marketingMetadata: LeadMarketingMetadata | undefined = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const utmId = searchParams.get('utm_id');
    const utmSource = searchParams.get('utm_source');

    return utmId && utmSource ? { utmId, utmSource } : undefined;
  }, [search]);

  const submitAnswers = useCallback(
    async (answers: VersionedAnswers): Promise<void> => {
      if (!currentStep) return;
      dispatch(
        startFlowOperation({
          stepId: currentStep?.id,
          answers: answers,
          lang,
          marketingMetadata,
          navigate,
        }),
      );
    },
    [dispatch, currentStep, lang, marketingMetadata, navigate],
  );

  const onAnswerComplete = useCallback((fieldId: string) => {
    TypewriterTracking.completedField({
      fieldId,
      hashedId: null,
    });
  }, []);

  const onError = useCallback((fieldId: string, error?: string) => {
    TypewriterTracking.errorOccurred({
      hashedId: null,
      error: `Field ${fieldId} error: ${error}`,
    });
  }, []);

  const onInfoIconClick = useCallback(() => {
    TypewriterTracking.clickedButton({
      hashedId: null,
      buttonName: ButtonName.moreInformation,
    });
  }, []);

  if (!currentStep || !questionnaireEngine) return null;

  const questionProps: StepProps = {
    step: currentStep || {},
    submitAnswers,
    isLoading: isApplicationLoading,
    versionedAnswers: new VersionedAnswers({ v1: {}, v2: {} }),
    onAnswerComplete,
    onInfoIconClick,
    onError,
  };

  return <GenericLinkLanding {...questionProps} questionnaireEngine={questionnaireEngine} />;
}
