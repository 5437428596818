import styled, { css } from 'styled-components';

const tooltipCss = css`
  &:after {
    content: none;
  }

  &:before {
    z-index: 1;
    position: absolute;
    opacity: 1;
    content: attr(data-tooltip-text);
    font-weight: ${(props) => props.theme.fonts.weight.semibold};
    text-align: center;
    height: 20px;
    width: 100px;
    top: -30px;
    left: -34px;
    background-color: transparent;
  }
`;

export const SliderWrapper = styled.div<{ hasTooltip?: boolean }>`
  position: relative;
  height: 50px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    height: 50px;
  }

  .MuiSlider-rail {
    background-color: ${(props) => props.theme.colors.grey[20]};
    border: 1px solid ${(props) => props.theme.colors.grey[50]};
    border-radius: 10px;
    height: 8px;
    left: 0;
    top: 25px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 25px;
    }
  }

  .MuiSlider-thumb {
    background-color: ${(props) => props.theme.colors.grey[0]};
    border: 2px solid ${(props) => props.theme.colors.primary.default};
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: unset;
    height: 32px;
    outline: none;
    padding: 0px;
    width: 32px;
    z-index: 2;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      height: 32px;
      width: 32px;
    }

    &:focus,
    &:hover {
      border-width: 3px;
    }

    ${(props) => props.hasTooltip && tooltipCss};
  }

  .MuiSlider-track {
    background-color: ${(props) => props.theme.colors.primary[40]};
    border: 1px solid ${(props) => props.theme.colors.primary.default};
    border-radius: 4px;
    height: 8px;
    position: absolute;
    top: 25px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 25px;
    }
  }
`;

export const SliderLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
`;
