export default {
  loading: {
    title: 'En traitement...',
    body: 'Veuillez patienter pendant que nous traitons votre demande.',
  },
  error: {
    title: 'Nous sommes désolés',
    body: `<p>Votre proposition n’a pu être soumise.</p>
      <p>Dites-nous le meilleur moment pour vous joindre dans les 48 prochaines heures ouvrables. Un conseiller vous aidera à soumettre votre proposition.</p>`,
  },
  responses: {
    accepted: {
      title: 'Votre proposition a été soumise',
      body: `<p>Certaines validations sont requises pour confirmer l'acceptation de votre proposition.</p>
        <p>Vous recevrez la confirmation de la décision par la poste d'ici 5 à 10 jours ouvrables. La police d’assurance entrera en vigueur seulement lorsque vous recevrez cette confirmation.</p>
        <p>Vous êtes protégé pendant que nous étudions votre proposition d’assurance.</p>`,
    },
    referred: {
      title: 'Votre proposition a été soumise',
      body: `<p>Votre dossier a été transmis à l'un de nos analystes qui pourrait vous appeler pour obtenir des informations supplémentaires.</p>
        <p>Une fois l'analyse complétée, une confirmation de la décision vous sera envoyée par la poste. La police d'assurance entrera en vigueur seulement lorsque vous recevrez cette confirmation.</p>
        <p>Vous êtes protégé pendant que nous étudions votre proposition d’assurance. Pour tous les détails, téléchargez l'avenant d'Assurance provisoire.</p>`,
    },
    conditional: {
      title: 'Votre proposition a été soumise',
      body: `Votre demande d'assurance vie a été acceptée sous condition, sous réserve d'un examen par l'un de nos experts. Nous confirmerons notre décision dans les 48 heures. Pendant cette période, votre assurance vie est en vigueur, sous certaines conditions.`,
    },
    denied: {
      title: 'Votre proposition a été refusée',
      body: 'Certaines réponses que vous avez fournies dans le questionnaire ne nous permettent pas de donner suite à votre demande.',
    },
  },
  download: {
    temporaryInsurance: `Pour plus d'information, <a href="%{url}" target="_blank" noopener noreferrer>téléchargez</a> l'amendement temporaire à l'assurance`,
    application: '<a href="%{url}" target="_blank" noopener noreferrer>Téléchargez</a> votre Application',
  },
  confirmationNumber: 'Numéro de confirmation pour vos archives:',
  confirmationCryptoSignature: 'Confirmation Crypto Signature:',
  contactInfo: {
    moreInfo:
      'Pour toutes les questions, modifications ou pour annuler votre proposition, veuillez communiquer avec un de nos représentants au <a href="tel:{{phoneNumber}}">{{phoneNumber}}</a>',
    or: 'ou',
  },
};
