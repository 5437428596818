import { ReactNode, ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

type Props = {
  children: ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  },
});

export function ReactQueryClientProvider(props: Props): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development',
         so you don't need to worry about excluding them during a production build. */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
