export default {
  title: 'Merci. Votre requête a été envoyée',
  subtitle: {
    single: 'Je vais analyser vos réponses et vous contacter pour compléter votre soumission.',
    plural: 'Nous allons analyser vos réponses et vous contacter pour compléter votre soumission.',
  },
  subtitle2: {
    single: 'Je communiquerai avec vous prochainement!',
    plural: '',
  },
  nextSteps: {
    title: 'Prochaines Étapes',
    contactYou: {
      single: 'Votre conseiller vous contactera sous peu',
      plural: 'Nous vous contacterons sous peu.',
    },
  },
};
