import queryString from 'query-string';
import { Dispatch } from 'redux';

import { text } from '../../Localization/Localizer';
import ApiService from '../../Services/ApiService';
import { getProducts } from '../InsuranceApplication/InsuranceApplicationOperations';
import { notificationSlice } from '../Notification/NotificationSlice';

export const switchLanguage =
  (appId: string | null, language: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (appId) {
      try {
        await ApiService.consumer.updateLanguage(appId, language);
        await getProducts(appId)(dispatch);
      } catch (error: any) {
        dispatch(notificationSlice.actions.setError({ message: text('apiErrors.unexpected') }));
      }
    }
    const parsedUrl = queryString.parseUrl(window.location.href);
    parsedUrl.query.lang = language;
    // deepcode ignore OR: attackers are not able to control the start of the string passed to the redirection API
    window.location.replace(queryString.stringifyUrl(parsedUrl));
  };
