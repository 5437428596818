import { ContextFilter } from '@breathelife/types';

import { LegacyProductInfo, RepeatableSection } from '../Models/Product';

export function getRepeatableSections(product: LegacyProductInfo, contextFilter?: ContextFilter): RepeatableSection[] {
  const repeatableSections = product.details?.repeatableSections ?? [];

  if (!contextFilter) return repeatableSections;

  return repeatableSections.filter((section) => (section.contextFilters ?? []).includes(contextFilter));
}
