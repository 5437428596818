import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MessageType } from '@breathelife/ui-components';

type CommonState = {
  title?: string;
  message?: string;
  autoHideDuration?: number;
};

export type SetNotificationState = CommonState & { type?: MessageType };
export type SetErrorState = CommonState;
export type SetSuccessState = CommonState;

export type NotificationState = SetNotificationState;

type ActionsType = {
  setNotification: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => void;
  setError: (state: NotificationState, { payload }: PayloadAction<SetErrorState>) => void;
  setSuccess: (state: NotificationState, { payload }: PayloadAction<SetSuccessState>) => void;
};

const defaultState = {};

export const notificationSlice = createSlice<NotificationState, ActionsType>({
  name: 'consumerFlow/notification',
  initialState: defaultState,
  reducers: {
    setNotification: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => {
      state.type = payload.type;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
    },
    setError: (state: NotificationState, { payload }: PayloadAction<SetErrorState>) => {
      state.type = MessageType.error;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
    },
    setSuccess: (state: NotificationState, { payload }: PayloadAction<SetSuccessState>) => {
      state.type = MessageType.success;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
    },
  },
});
