import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';

import { logger } from '@breathelife/monitoring-frontend';
import { Theme } from '@breathelife/types';

import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import { themeSlice } from '../Theme/ThemeSlice';

const fallbackTheme = {
  colorRanges: {
    primary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    secondary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    tertiary: {
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      default: '#bcbcbc',
    },
    grey: {
      '0': '#ffffff',
      '10': '#f6f6f6',
      '20': '#eeeeee',
      '30': '#e6e6e6',
      '40': '#cccccc',
      '50': '#bcbcbc',
      '60': '#999999',
      '70': '#757575',
      '80': '#666666',
      '90': '#333333',
      '100': '#000000',
    },
  },
};

export const fetchTheme =
  (navigate: NavigateFunction) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await ApiService.fetchDefaultTheme();
      dispatch(themeSlice.actions.setTheme({ theme: response.data }));
    } catch (error: any) {
      dispatch(themeSlice.actions.setHasErrorPayload({ hasLoadingError: true }));
      const message = `Unable to load theme in the consumer flow. Error: ${JSON.stringify(error)}`;
      logger.error(message);

      dispatch(themeSlice.actions.setTheme({ theme: fallbackTheme as Theme }));

      navigate(Urls.error.fullPath);
    }
  };
