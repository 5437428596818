import { Box } from '@breathelife/mui';
import { ReactElement, Fragment, useContext } from 'react';

import { IconName } from '@breathelife/types';
import { AdvisorInfoPanel, CloudinaryProps, GhostLinkButton, Icon, Text, Title } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { assigneeIsInterlocutor } from '../../Helpers/interlocutorHelper';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { text } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { AdvisorContainer, NextStepsContainer } from './Styles';
import { useGetAdvisorTitleFallback } from '../../Hooks/useGetAdvisorTitleFallback';

type ContactConfirmationPageProps = {
  applicationAssignee: ApplicationAssignee | undefined;
  cloudinaryProps: CloudinaryProps;
};

export function ContactConfirmationPage(props: ContactConfirmationPageProps): ReactElement | null {
  const { interlocutorIds } = useContext(CarrierContext);
  const { applicationAssignee, cloudinaryProps } = props;
  const isInterlocutor = assigneeIsInterlocutor(interlocutorIds, applicationAssignee);
  const pluralOrSingle = isInterlocutor ? 'plural' : 'single';
  const advisorTitleFallback = useGetAdvisorTitleFallback();

  return (
    <CenteredLayout
      headerVariant='default'
      hideProgress
      hideScheduleCall
      isLoading={false}
      offsetContent={<Icon name={IconName.confirmation} size='40px' />}
      title={text('contactConfirmation.title')}
      subtitle={<ContactConfirmationPageSubtitle isPlural={isInterlocutor} />}
    >
      <Fragment>
        <NextStepsContainer mt={4} p={3}>
          <Box mb={3} data-testid='contact-confirmation-next-step'>
            <Title level={4}>{text(`contactConfirmation.nextSteps.title`)}</Title>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box lineHeight='0' mx={1.5}>
              <Icon name={IconName.contact} size='36px' />
            </Box>
            <Text>{text(`contactConfirmation.nextSteps.contactYou.${pluralOrSingle}`)}</Text>
          </Box>
        </NextStepsContainer>
        {applicationAssignee?.name && (
          <AdvisorContainer textAlign='center' py={3}>
            <Box mb={2}>
              <AdvisorInfoPanel
                alignment='vertical'
                advisorInfo={{
                  name: applicationAssignee.name,
                  title: applicationAssignee.subgroup?.name || applicationAssignee.group?.name || advisorTitleFallback,
                  imgUrl: applicationAssignee.picture,
                }}
                cloudinaryProps={cloudinaryProps}
                textAlign='center'
                imgSettings={{
                  height: 61,
                  width: 61,
                }}
              />
            </Box>
            {applicationAssignee.phone && (
              <GhostLinkButton href={`tel:${applicationAssignee.phone}`}>
                <Icon name={IconName.phone} size='12' />
                {applicationAssignee.phone}
              </GhostLinkButton>
            )}
          </AdvisorContainer>
        )}
      </Fragment>
    </CenteredLayout>
  );
}

function ContactConfirmationPageSubtitle(props: { isPlural: boolean }): ReactElement {
  const { isPlural } = props;
  const pluralOrSingle = isPlural ? 'plural' : 'single';
  const subtitle2 = text(`contactConfirmation.subtitle2.${pluralOrSingle}`);

  return (
    <Fragment>
      <Box mb={2}>
        <Text grey={90}>{text(`contactConfirmation.subtitle.${pluralOrSingle}`)}</Text>
      </Box>
      {subtitle2 && <Text grey={90}>{text(`contactConfirmation.subtitle2.${pluralOrSingle}`)}</Text>}
    </Fragment>
  );
}
