export default {
  navigation: {
    continue: 'Continue',
  },
  age: {
    ageValue: '{{age}} years old',
    selectAge: 'Select Age',
  },
  referenceNumber: {
    short: 'Ref number',
    long: 'Reference number',
  },
  name: {
    firstName: 'First name',
    lastName: 'Last name',
  },
  heightWeight: {
    height: 'Height',
    weight: 'Weight',
    selectHeight: 'Select your height',
    selectWeight: 'Select your weight',
  },
  cheque: {
    name: 'Check',
    transit: 'Transit No.',
    institution: 'Institution',
    accountNumber: 'Account No.',
    codePlaceholder: 'Enter code',
    accountNumberPlaceholder: 'Enter the account number',
    chequeInformation: 'You can find this information at the bottom of one of your bank cheques.',
  },
  submission: {
    processing: 'Processing',
    thankYou: 'Thank you for your patience',
  },
  errors: {
    appAlreadySubmitted: 'Cannot change your answer on an already submitted application.',
    unexpected: 'An unexpected error has been detected, please try again or contact us.',
  },
  perMonth: '/month',
};
