import { Fragment, ReactElement, ReactNode } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { CloseIcon as BaseCloseIcon, Dialog, IconButton } from '@breathelife/mui';

type ModalLayoutProps = {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  submitButton: ReactNode;
  children: ReactNode;
  bodyVariant?: BodyVariant;
  fullScreen?: boolean;
};

type BodyVariant = 'default' | 'no-padding';

const Header = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.grey[20]};
  padding: 24px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.colors.grey[90]};
  margin: 0;
`;

type BodyProps = {
  bodyStyling: FlattenSimpleInterpolation;
};

const Body = styled.div<BodyProps>`
  ${(props) => props.bodyStyling}
`;

const CloseIcon = styled(BaseCloseIcon)`
  &&& {
    color: ${(props) => props.theme.colors.grey[70]};
    opacity: 0.5;
  }
`;

const defaultBody = css`
  padding: 24px 32px;
`;

const noPaddingBody = css`
  padding: 0;
`;

const bodyVariantToCssMap: { [variant in BodyVariant]: FlattenSimpleInterpolation } = {
  ['default']: defaultBody,
  ['no-padding']: noPaddingBody,
};

export function ModalLayout(props: ModalLayoutProps): ReactElement | null {
  const { children, closeModal, fullScreen, isOpen, title, bodyVariant } = props;
  const bodyStyling = bodyVariantToCssMap[bodyVariant ?? 'default'];

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} onClose={closeModal} style={{ zIndex: 1301 }} aria-labelledby={title}>
      <Fragment>
        <Header>
          <Title>{title}</Title>
          {/* TODO close button with 'close' text */}
          <IconButton size='small' onClick={props.closeModal}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Body bodyStyling={bodyStyling}>{children}</Body>
      </Fragment>
    </Dialog>
  );
}
