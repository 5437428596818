import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { toCurrency } from '../../Localization/Localizer';

type Props = {
  amount: number;
  suffix: string;
  precision?: number;
  responsive?: boolean;
};

const Currency = styled.p<{ responsive?: boolean }>`
  color: ${(props) => props.theme.colors.grey[90]};
  font-weight: ${(props: any) => props.theme.fonts.weight.bold};
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.4px;
  white-space: nowrap;
  ${(props) =>
    props.responsive &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
        font-size: 16px;
      }
    `}
`;

const Suffix = styled.p<{ responsive?: boolean }>`
  color: ${(props) => props.theme.colors.grey[80]};
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.responsive &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
        font-size: 13px;
      }
    `}
`;

export function CurrencyText(props: Props): ReactElement {
  return (
    <Box display='flex' alignItems='center'>
      <Box>
        <Currency responsive={props.responsive}>{toCurrency(props.amount, { precision: props.precision })}</Currency>
      </Box>
      <Box ml={1} overflow='hidden'>
        <Suffix responsive={props.responsive}>{props.suffix}</Suffix>
      </Box>
    </Box>
  );
}
