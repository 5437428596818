import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const ErrorImage = styled.img`
  width: 400px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    width: 280px;
    margin-bottom: 60px;
  }
`;
