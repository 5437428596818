import { SubmitResponse } from '@breathelife/crypto-material';
import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { OutcomeCode, SignatureType, SubmissionSource, QuestionnaireBlueprint } from '@breathelife/types';

import { SubmissionError } from '../../Services/Errors/SubmissionError';
import * as SubmissionService from '../../Services/SubmissionService';
import { submitApplicationForExternalSignature } from '../../Services/SubmissionService';
import { insuranceApplicationSlice } from '../InsuranceApplication/InsuranceApplicationSlice';
import { ConsumerFlowStore, Dispatch } from '../Store';
import { ErrorStep, submissionSlice } from './SubmissionSlice';

const actions = submissionSlice.actions;

const submitApplication =
  (applicationId: string, submissionSource: SubmissionSource, blueprint?: QuestionnaireBlueprint) =>
  async (dispatch: Dispatch): Promise<SubmitResponse> => {
    const submitResponse = await SubmissionService.submitApplication(applicationId, submissionSource);

    // Update application to include the confirmationNumber
    dispatch(
      insuranceApplicationSlice.actions.setInsuranceApplication({
        insuranceApplication: { ...submitResponse.application, blueprint },
      }),
    );

    return submitResponse;
  };

const cryptoSignApplication =
  (applicationId: string, digest: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const cryptoMaterial = SubmissionService.generateCryptoMaterial({ digest });
    dispatch(actions.setCryptoMaterial({ cryptoMaterial }));

    const signResponse = await SubmissionService.signApplicationUsingCrypto(applicationId, cryptoMaterial);
    dispatch(
      actions.setSignResponse({
        policyNumber: signResponse.policyNumber as string,
      }),
    );
  };

/**
 * Switcher operation that submits the application and takes the appropriate next step that corresponds to
 * the signature configuration. This should be the only function that is exposed to the consumer-flow UI.
 *
 * @param applicationId   ID of the application being submitted
 * @param signatureType   Type of signature flow used on this module
 */

export const processApplicationSubmission =
  (applicationId: string, signatureType: SignatureType, vendorCode: string) =>
  async (dispatch: Dispatch, getStore: () => ConsumerFlowStore): Promise<void> => {
    dispatch(actions.setIsStarted({ isSubmissionStarted: true }));

    TypewriterTracking.initiatedApplicationSubmission({
      hashedId: hash(applicationId),
    });

    const blueprint = getStore().consumerFlow.insuranceApplication.insuranceApplication?.blueprint;

    try {
      const submissionResponse = await dispatch(
        submitApplication(applicationId, SubmissionSource.consumerFlow, blueprint),
      );
      //TODO: As part of https://breathelife.atlassian.net/browse/DEV-12163 , this will need to be changed after the multi-insured support is added.
      const outcome = submissionResponse.state as OutcomeCode;
      dispatch(actions.createDataLayerEvent({ outcome, vendorCode }));
      dispatch(actions.setDecisionOutcome({ outcome }));

      if (submissionResponse.application.submitted) {
        switch (signatureType) {
          case SignatureType.cryptoSignature:
            await dispatch(cryptoSignApplication(submissionResponse.id, submissionResponse.digest));
            break;

          case SignatureType.externalSignature:
            await submitApplicationForExternalSignature(applicationId);
            break;

          case SignatureType.eSignature:
          default:
            // TODO: Add Redux operation that calls to E-Signature service
            throw new Error('Signature operation not yet implemented!');
        }
      }

      await dispatch(actions.setIsSuccess({ isSubmissionSuccess: true }));
    } catch (err: any) {
      if (err instanceof SubmissionError) {
        await dispatch(actions.setErrorStep({ errorStep: err.message as ErrorStep }));
      }

      throw err;
    }
  };
