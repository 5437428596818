// Needs to be overriden by the carrier
export const carrierSpecificStrings = {
  carrierName: null,
  legal: {
    footer: null,
  },
  errors: {
    appAlreadySubmitted: null,
    unexpected: null,
  },
  scheduleCall: {
    legal: null,
    customerServicePhoneNumber: null,
  },
};
