import { ReactElement } from 'react';

import {
  getMonthlyPremiumFromApplication,
  getSelectedProductInformation,
} from '../../Redux/InsuranceApplication/InsuranceApplicationSelectors';
import { PriceUpdateStep } from '../../Components/PriceUpdateStep/PriceUpdateStep';
import { useCxSelector } from '../../Hooks/useCxSelector';

type Props = {
  onChangeAnswer: () => void;
  onContinue: () => void;
  useApplicationMonthlyPremium?: boolean;
  nextStepButtonText?: string;
  isNextBtnLoading: boolean;
};

export function PriceUpdateStepContainer(props: Props): ReactElement | null {
  const productInformation = useCxSelector((store) =>
    getSelectedProductInformation(store, { useApplicationMonthlyPremium: props.useApplicationMonthlyPremium }),
  );

  const monthlyPremium = useCxSelector(getMonthlyPremiumFromApplication);

  if (!props.useApplicationMonthlyPremium && !productInformation) {
    return null;
  }

  return (
    <PriceUpdateStep
      productInformation={productInformation ?? undefined}
      monthlyPremium={monthlyPremium}
      onChangeAnswer={props.onChangeAnswer}
      onContinue={props.onContinue}
      nextStepButtonText={props.nextStepButtonText}
      isNextBtnLoading={props.isNextBtnLoading}
    />
  );
}
