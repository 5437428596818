export type Address = {
  streetNumber: string;
  streetName: string;
  streetAddress: string;
  apartment: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  country: string;
};

export enum CanadianProvinceCodes {
  alberta = 'AB',
  britishColumbia = 'BC',
  manitoba = 'MB',
  newBrunswick = 'NB',
  newfoundland = 'NL',
  novaScotia = 'NS',
  nunavut = 'NU',
  northwestTerritories = 'NT',
  ontario = 'ON',
  princeEdwardIsland = 'PE',
  quebec = 'QC',
  saskatchewan = 'SK',
  yukon = 'YT',
}
