import { ReactElement } from 'react';

import { TransactionFlowSidebar as Sidebar } from '@breathelife/ui-components';

import { CarrierLogo } from '../../Components/CarrierLogo/CarrierLogo';

type Props = {
  title: string;
};

export function TransactionFlowSidebar(props: Props): ReactElement {
  const emptyComponent = null;
  return (
    <Sidebar title={props.title} logo={<CarrierLogo />}>
      {/*
        TODO: FIXME: previously, the transaction flow layout required to have Component injected per carrier.
        We moved toward a single entry point approach for all carrier.
        If the transactionFlow is going to be reused, this will need to be reimplemented based on new requirements.
       */}
      {emptyComponent}
    </Sidebar>
  );
}
