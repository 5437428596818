import { Dispatch } from 'redux';

import { text } from '../../Localization/Localizer';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { getApplicationAssignee, getAssigneeInfoFromToken } from '../../Services/LeadsService';
import { notificationSlice } from '../Notification/NotificationSlice';
import { applicationAssigneeSlice } from './ApplicationAssigneeSlice';

const { actions } = applicationAssigneeSlice;
const notificationActions = notificationSlice.actions;

export const fetchApplicationAssignee =
  (applicationId?: string, token?: string, method?: AccessTokenMethod) => async (dispatch: Dispatch) => {
    if (!applicationId && (!token || !method)) return;

    dispatch(actions.setIsLoading(true));

    try {
      let agent: ApplicationAssignee | null = null;

      if (token && method) {
        agent = await getAssigneeInfoFromToken(method, token);
      } else if (applicationId) {
        agent = await getApplicationAssignee(applicationId);
      }

      if (agent) dispatch(actions.setApplicationAssignee(agent));
    } catch (error: any) {
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchApplicationAssignee') }));
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
