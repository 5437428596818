import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';

import { logger } from '@breathelife/monitoring-frontend';

import Urls from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import { settingsSlice } from '../Settings/SettingsSlice';

export const fetchSettings =
  (navigate: NavigateFunction) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await ApiService.fetchPublicSettings();
      dispatch(settingsSlice.actions.setSettings({ settings: response.data }));
    } catch (error: any) {
      dispatch(settingsSlice.actions.setHasErrorPayload({ hasLoadingError: true }));
      const message = `Unable to load settings in the consumer flow. Error: ${JSON.stringify(error)}`;
      logger.error(message);

      navigate(Urls.error.fullPath);
    }
  };
