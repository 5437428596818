import _ from 'lodash';

import { BestMomentType, OnSubmitScheduleDataType, TimeFrame } from '@breathelife/ui-components';

import { CommunicationPreferences } from '../../Models/ApplicationAssignee';
import { LeadCommunicationData } from '../../Services/LeadsService';

export enum DayOfTheWeekMap {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export function getApiFormattedScheduleCallPreferences(data: OnSubmitScheduleDataType): LeadCommunicationData {
  const { firstName, lastName, email, phoneNumber, preferredMethods } = data;

  const schedules = _.map(data.bestMoments, (bestMoment: TimeFrame[], key: string) => ({
    day: Number(key),
    time: bestMoment,
  }));

  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    preferredMethods,
    ['leads-communication-schedules']: schedules,
  };
}

export function getFormattedPreferenceFromApiScheduleCall(
  leadCommunicationData: LeadCommunicationData,
): CommunicationPreferences {
  const preferredMethods = leadCommunicationData.preferredMethods;

  const bestMoments: BestMomentType = _.reduce(
    leadCommunicationData['leads-communication-schedules'],
    (moments, schedule) => {
      const { day, time } = schedule;
      moments[day.toString()] = time;
      return moments;
    },
    {} as BestMomentType,
  );

  return {
    bestMoments,
    preferredMethods,
  };
}
