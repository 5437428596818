import { ReactElement, Fragment } from 'react';

import { Box, Divider } from '@breathelife/mui';
import { QuotedInsuranceProduct } from '@breathelife/types';
import { List, ListItem, Title } from '@breathelife/ui-components';

import { text } from '../../../Localization/Localizer';
import { LegacyQuotedProduct } from '../../../Models/Product';

type Props = {
  product: LegacyQuotedProduct | QuotedInsuranceProduct;
};

export function ProductDetailsInfo({ product }: Props): ReactElement {
  const features = 'additionalFeatures' in product ? product.additionalFeatures : product.includedFeatures;

  return (
    <Box>
      {isProductQuotedInsuredProduct(product) && (
        <Fragment>
          <Box p={2}>
            <Box mb={1}>
              <Title level={2} variant={4}>
                {text('product.applicationDetails')}
              </Title>
            </Box>
            <Box fontSize={16}>{text('product.medicalExam')}</Box>
            <Box>{product.requiresMedicalExam ? text('product.yes') : text('product.no')}</Box>
          </Box>
          <Divider />
          <Box p={2}>
            <Box mb={2}>
              <Title level={2} variant={4}>
                {text('product.coverageFlexibility')}
              </Title>
            </Box>
            <Box mb={1}>
              <Box fontSize={16}>{text('product.renewable')}</Box>
              {product.renewable
                ? `${text('product.yes')}, ${text('product.untilAge')} ${product?.maxRenewalAge}`
                : `${text('product.no')}`}
            </Box>
            <Box mb={1}>
              <Box fontSize={16}>{text('product.convertible')}</Box>
              {product.convertible
                ? `${text('product.yes')}, ${text('product.untilAge')} ${product?.maxConversionAge}`
                : `${text('product.no')}`}
            </Box>
          </Box>
          <Divider />
        </Fragment>
      )}
      {features.length > 0 && (
        <Fragment>
          <Box p={2}>
            <Box mb={2}>
              <Title level={2} variant={4}>
                {text('product.options')}
              </Title>
            </Box>
            <List>
              {features.map((feature) => (
                <ListItem key={feature} iconType='checkmark'>
                  <Box mb={1}>
                    <p>{feature}</p>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider />
        </Fragment>
      )}
    </Box>
  );
}

function isProductQuotedInsuredProduct(
  product: LegacyQuotedProduct | QuotedInsuranceProduct,
): product is QuotedInsuranceProduct {
  return 'requiresMedicalExam' in product && 'convertible' in product && 'renewable' in product;
}
