import { ReactElement, Fragment, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { setA11yMessage } from '@breathelife/redux';
import { Helmet, Text, Title } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { a11yPageTitle, text } from '../../Localization/Localizer';
import { resetApplication as resetApplicationOperation } from '../../Redux/resetOperations';
import { Container, Content, ErrorImage } from './Styles';

type Props = {
  image?: string;
};

export function SessionExpired(props: Props): ReactElement {
  const dispatch = useDispatch();
  const applicationId =
    useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id) ?? null;
  const stepId = useCxSelector((store) => store.consumerFlow.step.currentStep?.id);
  const resetApplication = useCallback(() => {
    dispatch(resetApplicationOperation());
  }, [dispatch]);

  const pageTitle = a11yPageTitle('sessionExpired');

  useEffect(() => {
    setA11yMessage(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    resetApplication();
    TypewriterTracking.viewedStep({
      hashedId: hash(applicationId),
      stepId: stepId ?? '',
    });
  }, [resetApplication, applicationId, stepId]);

  const image = props.image && <ErrorImage src={props.image} alt={text('error')} />;

  return (
    <Fragment>
      <Helmet titleAttributes={{ title: pageTitle }} />
      <Container>
        <Content>
          <Title level={1}>{text('sessionExpired.titleNoReturn')}</Title>
          <Text align='center'>{text('sessionExpired.descriptionNoReturn')}</Text>
          {image}
        </Content>
      </Container>
    </Fragment>
  );
}
