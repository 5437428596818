import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment, useContext } from 'react';
import { useTheme } from 'styled-components';

import { CryptoMaterial } from '@breathelife/crypto-material';
import { IconName, Language, OutcomeCode } from '@breathelife/types';
import { SharedTheme, Text, Icon } from '@breathelife/ui-components';

import { ScheduleCallTabsContainer } from '../../Components/ScheduleCallTabs/ScheduleCallTabsContainer';
import { CarrierContext } from '../../Context/CarrierContext';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { text } from '../../Localization/Localizer';
import { ContactInfo } from './ContactInfo';
import { DownloadWidget } from './DownloadWidget';
import { ConfirmationBox, SignatureText } from './Styles';

export type Props = {
  isLoading: boolean;
  isSubmissionSuccess: boolean;
  outcome?: OutcomeCode;
  language: Language;
  cryptoMaterial?: CryptoMaterial;
  confirmationNumber?: string;
  temporaryInsurancePdfUrl?: string;
  applicationPdfUrl?: string;
};

export function SubmissionView(props: Props): ReactElement | null {
  const {
    isLoading,
    isSubmissionSuccess,
    outcome,
    language,
    cryptoMaterial,
    confirmationNumber,
    temporaryInsurancePdfUrl,
    applicationPdfUrl,
  } = props;
  const theme = useTheme() as SharedTheme;
  const { carrierInfo } = useContext(CarrierContext);

  const contactPhoneNumber = carrierInfo.contactPhoneNumber || '';
  const contactEmail = carrierInfo?.contactEmail;

  const title = isSubmissionSuccess
    ? text(`submitApplication.responses.${outcome}.title`)
    : text(`submitApplication.error.title`);

  const icon = isSubmissionSuccess ? (
    <Icon name={IconName.confirmation} size='60' />
  ) : (
    <Icon name={IconName.wait} size='60' color={{ primary: theme.colors.warning }} />
  );

  return (
    <CenteredLayout
      hideProgress
      hideScheduleCall={!isSubmissionSuccess}
      isLoading={isLoading}
      title={title}
      offsetContent={icon}
      headerVariant='default'
      subtitle=''
      loadingContent={{
        title: text('submitApplication.loading.title'),
        subtitle: text('submitApplication.loading.body'),
      }}
    >
      <Box mt={4} pb={4}>
        {isSubmissionSuccess && (
          <Fragment>
            <Text variant='copy' component='div'>
              {ReactHtmlParser(text(`submitApplication.responses.${outcome}.body`))}
            </Text>

            <Box my={3} textAlign='center'>
              {temporaryInsurancePdfUrl && (
                <DownloadWidget url={temporaryInsurancePdfUrl} document='temporaryInsurance' />
              )}

              {applicationPdfUrl && <DownloadWidget url={applicationPdfUrl} document='application' />}
            </Box>

            {confirmationNumber && (
              <ConfirmationBox my={3} p={2}>
                <Box mb={0.5}>
                  <Text variant='small-copy' noSpacing>
                    <strong>{text(`submitApplication.confirmationNumber`)}</strong>
                  </Text>
                </Box>
                <Text variant='small-copy' noSpacing>
                  {confirmationNumber}
                </Text>
              </ConfirmationBox>
            )}

            {!confirmationNumber && cryptoMaterial && (
              <ConfirmationBox my={3} p={2}>
                <Box mb={0.5}>
                  <Text variant='small-copy' noSpacing>
                    <strong>{text(`submitApplication.confirmationCryptoSignature`)}</strong>
                  </Text>
                </Box>
                <SignatureText variant='small-copy' noSpacing>
                  {cryptoMaterial.signature}
                </SignatureText>
              </ConfirmationBox>
            )}

            {contactPhoneNumber || contactEmail ? (
              <ContactInfo phoneNumber={contactPhoneNumber} email={contactEmail} />
            ) : null}
          </Fragment>
        )}

        {!isSubmissionSuccess && (
          <Fragment>
            <Text component='div'>{ReactHtmlParser(text(`submitApplication.error.body`))}</Text>
            <Box mt={4}>
              <ScheduleCallTabsContainer language={language} />
            </Box>
            {contactPhoneNumber || contactEmail ? (
              <ContactInfo phoneNumber={contactPhoneNumber} email={contactEmail} />
            ) : null}
          </Fragment>
        )}
      </Box>
    </CenteredLayout>
  );
}
