import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Settings } from '@breathelife/types';

export type SettingsState = {
  settings?: Settings;
  hasLoadingError: boolean;
};

type SettingsPayload = { settings: Settings };
type ErrorPayload = { hasLoadingError: boolean };

type ActionsType = {
  setSettings: (state: SettingsState, { payload }: PayloadAction<SettingsPayload>) => void;
  setHasErrorPayload: (state: SettingsState, { payload }: PayloadAction<ErrorPayload>) => void;
};

const defaultState = {
  hasLoadingError: false,
};

export const settingsSlice = createSlice<SettingsState, ActionsType>({
  name: 'consumerFlow/settings',
  initialState: defaultState,
  reducers: {
    setSettings: (state: SettingsState, { payload }: PayloadAction<SettingsPayload>) => {
      state.settings = payload.settings;
    },
    setHasErrorPayload: (state: SettingsState, { payload }: PayloadAction<ErrorPayload>) => {
      state.hasLoadingError = payload.hasLoadingError;
    },
  },
});
