import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalState } from '../../Models/Layout';

export type ModalPayload = { modalState: ModalState };

export type LayoutState = ModalPayload;

type ActionsType = {
  setModalState: (state: LayoutState, { payload }: PayloadAction<ModalPayload>) => void;
};

export const defaultState: LayoutState = {
  modalState: {
    isOpen: false,
  },
};

export const layoutSlice = createSlice<LayoutState, ActionsType>({
  name: 'consumerFlow/layout',
  initialState: defaultState,
  reducers: {
    setModalState: (state: LayoutState, { payload }: PayloadAction<ModalPayload>) => {
      state.modalState = payload.modalState;
    },
  },
});
