import { ReactElement } from 'react';

import { AdvisorInfoPanel } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { useGetAdvisorTitleFallback } from '../../Hooks/useGetAdvisorTitleFallback';

export function AdvisorInfoPanelContainer(): ReactElement | null {
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const advisorTitleFallback = useGetAdvisorTitleFallback();

  if (!applicationAssignee?.name) return null;

  return (
    <AdvisorInfoPanel
      advisorInfo={{
        name: applicationAssignee.name,
        title: applicationAssignee.subgroup?.name || applicationAssignee.group?.name || advisorTitleFallback,
      }}
    />
  );
}
