import styled, { css } from 'styled-components';

import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@breathelife/mui';

const outlinedCSS = css`
  border-radius: ${(props) => props.theme.metrics.button.borderRadius};
  border: 2px solid ${(props) => props.theme.colors.primary.default};
  &:hover,
  &:active,
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.default};
  }
`;

type StyledButtonProps = {
  minWidth?: number;
};

export const Button = styled(MuiButton).withConfig({
  shouldForwardProp: (prop) => !['minWidth'].includes(prop),
})<StyledButtonProps>`
  && {
    font-weight: bold;
    text-transform: none;
    box-shadow: none;
    border-radius: ${(props) =>
      props.theme.metrics.button.borderRadius ? props.theme.metrics.button.borderRadius : '0px'};
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
    ${({ variant }: MuiButtonProps) => variant === 'outlined' && outlinedCSS}
    ${({ size }: MuiButtonProps) => size === 'large' && 'font-size: 16px;'}
    ${(props) =>
      props.minWidth &&
      css`
        min-width: ${props.minWidth}px;
      `}
  }
`;
