export default {
  title: 'Thank you. Your request has been submitted',

  subtitle: {
    single: 'I will analyse your answers and reach out to you to complete your product submission.',
    plural: 'We will analyse your answers and reach out to you to complete your product submission.',
  },
  subtitle2: {
    single: 'I will get back to you very shortly!',
    plural: '',
  },
  nextSteps: {
    title: 'Next Steps',
    contactYou: {
      single: 'Your advisor will contact you',
      plural: 'We will get back to you shortly.',
    },
  },
};
