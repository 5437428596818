import { ReactElement } from 'react';

import { withResponsive } from '@breathelife/ui-components';

import { ErrorPage } from './ErrorPage';

export function ErrorPageContainer(): ReactElement | null {
  const ResponsiveErrorPage = withResponsive(ErrorPage);

  return <ResponsiveErrorPage />;
}
