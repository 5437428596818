export default {
  navigation: {
    continue: 'Continuer',
  },
  age: {
    ageValue: '{{age}} ans',
    selectAge: 'Selectionnez votre age',
  },
  referenceNumber: {
    short: 'N° Ref',
    long: 'N° de référence',
  },
  name: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
  },
  heightWeight: {
    height: 'Taille',
    weight: 'Poids',
    selectHeight: 'Sélectionnez votre taille',
    selectWeight: 'Sélectionnez votre poids',
  },
  cheque: {
    name: 'Chèque',
    transit: 'N° de Transit',
    institution: 'Institution',
    accountNumber: 'N° de Compte',
    codePlaceholder: 'Entrez le n°',
    accountNumberPlaceholder: 'Entrez le n° de compte',
    chequeInformation: 'Vous pouvez trouver ces informations au bas de l’un de vos chèques de banque.',
  },
  submission: {
    processing: 'En traitement',
    thankYou: 'Merci de votre patience',
  },
  errors: {
    appAlreadySubmitted: 'Votre application a déjà été soumise, vous ne pouvez pas la modifier.',
    unexpected: 'Une erreur inattendu a été detecté, veuillez reessayer ou nous contacter.',
  },
  perMonth: '/mois',
};
