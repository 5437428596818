import queryString from 'query-string';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { ResponsiveProps } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import Urls from '../../Navigation/Urls';
import { fetchApplicationAssignee } from '../../Redux/ApplicationAssignee/ApplicationAssigneeOperations';
import { communicationSlice } from '../../Redux/Communication/CommunicationSlice';
import {
  startFlow as startFlowOperation,
  StartFlowOptions,
} from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { HomePage } from './HomePage';

export type HomePageProps = {
  startFlow: (options: StartFlowOptions) => void;
  submitAnswer: (stepId: string, answer: any) => void;
  applicationAssignee?: ApplicationAssignee;
  isLoading: boolean;
  onScheduleButtonClick?: () => void;
} & ResponsiveProps;

function Container(): ReactElement | null {
  const location = useLocation();
  const navigate = useNavigate();
  const { features } = useContext(CarrierContext);

  const { token, method, id } = queryString.parse(location.search) as Record<string, string | undefined>;

  const [isPageReady, setIsPageReady] = useState(false);

  const dispatch = useDispatch();
  const appId = useCxSelector((state) => state.consumerFlow.insuranceApplication?.insuranceApplication?.id);
  const { assignee: applicationAssignee, isLoading: isApplicationAssigneeLoadingState } = useCxSelector(
    (store) => store.consumerFlow.applicationAssignee,
  );

  const isValidPrivateLink = token && (method === AccessTokenMethod.privateLink || method === AccessTokenMethod.email);
  const isValidPublicLink = token && method === AccessTokenMethod.publicLink;
  const isValidCobrowsingLink = token && method === AccessTokenMethod.coBrowsing;

  const startFlow = useCallback(
    (options: StartFlowOptions) => {
      const { token, method, id } = queryString.parse(location.search) as Record<string, string | undefined>;

      dispatch(
        startFlowOperation({
          ...options,
          appId: id,
          token,
          method,
          navigate,
        }),
      );
    },
    [dispatch, location.search, navigate],
  );

  useEffect(() => {
    if (!method) return;
    if (method === AccessTokenMethod.publicLink || method === AccessTokenMethod.privateLink) {
      dispatch(communicationSlice.actions.setHasAppliedThroughAdvisor(false));
    }
  }, [dispatch, method]);

  useEffect(() => {
    if (
      !features.selfServeApplication?.enabled &&
      !appId &&
      !id &&
      !(isValidPrivateLink || isValidPublicLink || isValidCobrowsingLink)
    ) {
      // If there is an existing application or token and method values are valid
      // we can continue to the homepage. Otherwise, we're dealing with an unsupported case

      navigate(Urls.fourOhFour.fullPath);
    }
  }, [
    appId,
    navigate,
    isValidPrivateLink,
    isValidPublicLink,
    isValidCobrowsingLink,
    id,
    features.selfServeApplication?.enabled,
    token,
  ]);

  useEffect(() => {
    if (isApplicationAssigneeLoadingState) {
      return;
    }

    if (applicationAssignee) {
      setIsPageReady(true);
      return;
    }

    if (isValidPublicLink || isValidPrivateLink || isValidCobrowsingLink || appId) {
      dispatch(fetchApplicationAssignee(appId, token, method as AccessTokenMethod));
    } else {
      setIsPageReady(true);
    }
  }, [
    dispatch,
    applicationAssignee,
    isApplicationAssigneeLoadingState,
    appId,
    token,
    method,
    isValidPublicLink,
    isValidCobrowsingLink,
    isValidPrivateLink,
  ]);

  if (!isPageReady) return null;

  return <HomePageComponent startFlow={startFlow} />;
}

function HomePageComponent(props: { startFlow: any }): ReactElement | null {
  const isApplicationLoading = useCxSelector((store) => store.consumerFlow.insuranceApplication.isLoading);
  const isStepLoading = useCxSelector((store) => store.consumerFlow.step.isLoading);
  const { landingStepsIds } = useCxSelector((store) => store.consumerFlow.configuration);
  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  return (
    <HomePage
      // TODO: pass allowSelfServe in the config features
      onCtaClick={() => props.startFlow({ firstStepId: landingStepsIds[0], allowSelfServe: false })}
      applicationAssignee={applicationAssignee}
      isLoading={isApplicationLoading || isStepLoading}
    />
  );
}

export const HomePageContainer = Container;
