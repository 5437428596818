import { text } from '../../Localization/Localizer';

enum ServiceErrorCode {
  ApplicationAlreadySubmitted = 'ApplicationAlreadySubmitted',
  UnexpectedError = 'UnexpectedError',
}

const LocalizedMessageString = {
  [ServiceErrorCode.ApplicationAlreadySubmitted]: 'quoteApplication.errors.appAlreadySubmitted',
  [ServiceErrorCode.UnexpectedError]: 'quoteApplication.errors.unexpected',
};

export function getLocalizedErrorMessage(serviceErrorCode?: ServiceErrorCode): string {
  let localizedErrorMessage = LocalizedMessageString[ServiceErrorCode.UnexpectedError];

  if (serviceErrorCode && serviceErrorCode in LocalizedMessageString) {
    localizedErrorMessage = LocalizedMessageString[serviceErrorCode];
  }

  return text(localizedErrorMessage);
}

export default ServiceErrorCode;
