import { createGlobalStyle, css } from 'styled-components';

import { iOSSupport } from '@breathelife/ui-components';

import { normalizer } from './Normalizer';

export const globalStyle = css`
  * {
    font-family: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.fonts.family.main;
    }} !important;
  }
  html {
    // This is needed since the react-responsive-modal library is putting this to hidden, which is not playing well
    // with the modal animation on mobile, since it's hiding a part of the page when scrolled
    overflow: visible !important;
  }
  body {
    background-color: ${(props) => props.theme.colors.grey[20]};
  }
  html,
  body,
  #root {
    height: 100%;
  }

  a {
    color: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.colors.base.primary;
    }};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => {
      // @ts-ignore TODO: fix type
      return props.theme.fonts.family.title;
    }};
    font-weight: normal;
  }

  button {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.primary;
      }} !important ;
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.softPrimary;
      }}
      inset !important ;
    box-shadow: 0 0 0px 1000px
      ${(props) => {
        // @ts-ignore TODO: fix type
        return props.theme.colors.base.softPrimary;
      }}
      inset !important ;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${iOSSupport}
  ${normalizer}
  ${globalStyle};
`;
