import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FieldGenerator, RenderContext, replaceHtmlTag } from '@breathelife/field-generator';
import {
  getAllSections,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingSection,
} from '@breathelife/questionnaire-engine';
import { navigationSlice } from '@breathelife/redux';
import { Language } from '@breathelife/types';
import { NavigationButtons, Text, Title } from '@breathelife/ui-components';

import { SectionCard } from '../../Components/SectionCard';
import { text } from '../../Localization/Localizer';
import { RenderingStep } from '../../Models/Step';
import { Props as StepProps } from '../../Pages/StepPage/StepPage';
import { generateQuestionWithStepIdUrl } from '../../Navigation/Urls';

type Props = Pick<StepProps, 'onBackButtonClick' | 'landingStepsIds'> & {
  renderingStep: RenderingStep;
  summaryQuestionnaire: RenderingQuestionnaire;
  language: Language;
  sectionId?: string;
  onNextClick: () => void;
  isNextBtnLoading: boolean;
};

export function SummaryView(props: Props): ReactElement | null {
  const { summaryQuestionnaire, language, onBackButtonClick, onNextClick, renderingStep, isNextBtnLoading } = props;

  const sections = getAllSections(summaryQuestionnaire);

  return (
    <Box mt={4}>
      {sections.map((section: RenderingSection) => (
        <SummarySection section={section} language={language} key={section.id} />
      ))}
      <Box px={{ xs: 3, md: 0 }}>
        <NavigationButtons
          onPreviousClick={onBackButtonClick}
          hidePrevious={false}
          hideNext={false}
          nextButtonText={renderingStep.nextStepButtonText || text('continue')}
          onNextClick={onNextClick}
          isNextBtnLoading={isNextBtnLoading}
        />
      </Box>
    </Box>
  );
}

function SummarySection(props: { section: RenderingSection; language: Language }): ReactElement | null {
  const { section, language } = props;

  if (!section.visible) return null;

  const subsections = section.subsections.filter((subsection) => subsection.visible);

  return (
    <SectionCard py={3} px={{ xs: 3, md: 0 }}>
      {section.title && (
        <Box mb={3}>
          <Title level={4}>{section.title}</Title>
        </Box>
      )}

      {subsections.map((subsection) => (
        <Fragment key={subsection.id}>
          {subsection.title && (
            <Box my={3}>
              <Text variant='small-body' grey={90}>
                <strong>{subsection.title}</strong>
              </Text>
            </Box>
          )}
          {subsection.text && (
            <Box my={3}>
              <Text variant='small-body' grey={90}>
                {replaceHtmlTag(subsection.text, 'strong', 'span')}
              </Text>
            </Box>
          )}
          {subsection.questions.map((question: RenderingQuestion) => (
            <SummaryQuestion key={question.id} stepId={subsection.id} question={question} language={language} />
          ))}
        </Fragment>
      ))}
    </SectionCard>
  );
}

function SummaryQuestion(props: {
  question: RenderingQuestion;
  language: Language;
  stepId: string;
}): ReactElement | null {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stepId, question, language } = props;

  return (
    <FieldGenerator
      context={RenderContext.summary}
      question={question}
      locale={language}
      stepId={stepId}
      currency='CAD'
      onEditClick={(stepId: string) => {
        dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
        const url = generateQuestionWithStepIdUrl(stepId);
        navigate(url);
      }}
    />
  );
}
