import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import { RenderingStep } from '../../Models/Step';
import { Props as StepProps } from '../../Pages/StepPage/StepPage';
import * as applicationOperations from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import ApiService from '../../Services/ApiService';
import { SummaryView } from './SummaryView';

type Props = Pick<StepProps, 'onBackButtonClick' | 'landingStepsIds'> & {
  renderingStep: RenderingStep;
  language: Language;
  onNextClick: () => void;
  isNextBtnLoading: boolean;
};

export function SummaryViewContainer(props: Props): ReactElement | null {
  const dispatch = useDispatch();
  const { language } = props;

  const applicationState = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const applicationId = applicationState.insuranceApplication?.id ?? null;
  const monthlyPremium = applicationState?.insuranceApplication?.monthlyPremium;
  const referencePremium = applicationState?.referencePremium;

  useEffect(() => {
    dispatch(applicationOperations.createReferencePremium(referencePremium ?? monthlyPremium ?? 0));
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summaryQuestionnaire, setSummaryQuestionnaire] = useState<RenderingQuestionnaire>([]);

  useEffect(() => {
    async function fetchSummary(applicationId: string): Promise<void> {
      setIsLoading(true);

      try {
        const response = await ApiService.fetchSummary(applicationId, { lang: language });
        setSummaryQuestionnaire(response.data);
      } catch (error: any) {
        dispatch(
          notificationSlice.actions.setError({
            message: text('product.error.fetchSummary'),
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }

    if (!applicationId) return;

    fetchSummary(applicationId);
  }, [dispatch, applicationId, language]);

  if (isLoading) {
    return <Loader />;
  }

  if (!summaryQuestionnaire.length) return null;

  return <SummaryView {...props} summaryQuestionnaire={summaryQuestionnaire} />;
}
