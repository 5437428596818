import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { IconName } from '@breathelife/types';
import { Text, Icon } from '@breathelife/ui-components';

import { text } from '../../../Localization/Localizer';
import { Content } from './Styles';

export function AlmostCompletedMessage(): ReactElement {
  return (
    <Content display='flex' alignItems='center' px={3} py={4}>
      <Box lineHeight='0px' mx={1.5}>
        <Icon name={IconName.watch} size='38px' />
      </Box>
      <Text variant='small-copy'>{text('product.almostCompleted')}</Text>
    </Content>
  );
}
