import { Middleware } from 'redux';

import { createTrackerMiddleware as sharedCreateTrackerMiddleware } from '@breathelife/redux';
import { Language } from '@breathelife/types';

import { ConsumerFlowStore } from '../Store';

function getApplicationId(store: ConsumerFlowStore): string | null {
  return store.consumerFlow.insuranceApplication.insuranceApplication?.id ?? null;
}

export function createTrackerMiddleware(isTrackedPage: (path: string) => boolean): Middleware {
  return sharedCreateTrackerMiddleware<ConsumerFlowStore>(isTrackedPage, getApplicationId, Language.en);
}
