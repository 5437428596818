export default {
  modal: {
    title: 'Hi,\nHow would you like to communicate?',
    emailUs: {
      single: 'Send me an email',
      plural: 'Send us an email',
    },
    legal: {
      life: '',
    },
    submit: 'Submit',
    subtitle: {
      single: {
        callYou: 'Please provide me your contact information and I’ll be in touch with you soon.',
        callUs: 'Reach me via the actions at the bottom of this page.',
      },
      plural: {
        callYou: 'Please provide us your contact information and we’ll be in touch with you soon.',
        callUs: 'Reach us via the actions at the bottom of this page.',
      },
    },
    confirmation: {
      single: {
        title: "I'll be in touch soon!",
        subtitle: 'Your request has been successfully sent. I will contact you shortly.',
      },
      plural: {
        title: 'We’ll be in touch soon!',
        subtitle: 'Your request has been successfully sent. We will contact you shortly.',
      },
    },
  },
  error: 'Error while submitting communication preferences',
};
