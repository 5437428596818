import { Box } from '@breathelife/mui';
import styled from 'styled-components';

export const NextStepsContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[30]};
`;

export const AdvisorContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;
