import { Language } from '@breathelife/types';

import Address from './Address/english';
import ApiErrors from './ApiErrors/english';
import Blocking from './Blocking/english';
import ContactConfirmation from './ContactConfirmation/english';
import engineValidation from './EngineValidation/english';
import ErrorPage from './ErrorPage/english';
import Header from './Header/english';
import Home from './HomePage/english';
import Payment from './Payment/english';
import Pdf from './Pdf/english';
import Product from './Products/english';
import QuoteApplication from './QuoteApplication/english';
import ScheduleCall from './ScheduleCall/english';
import SessionExpired from './SessionExpired/english';
import Steps from './Steps/english';
import SubmitApplication from './SubmitApplication/english';
import ValidationErrors from './ValidationErrors/english';
import { carrierSpecificStrings } from './carrierSpecificStrings';

export default {
  product: Product,
  sessionExpired: SessionExpired,
  ...carrierSpecificStrings,
  loading: 'loading...',
  pdf: Pdf,
  leftPanel: {
    clientAge: '{{age}} years old',
    clientGender: {
      male: 'Male',
      female: 'Female',
    },
    clientSmoker: {
      true: 'Smoker',
      false: 'Non Smoker',
    },
  },
  allRightReserved: 'ALL RIGHTS RESERVED {{year}}',
  date: {
    year: 'Year',
    month: 'Month',
    day: 'Day',
  },
  confirm: 'Confirm',
  continue: 'Continue',
  understand: 'I understand',
  header: Header,
  validationErrors: ValidationErrors,
  validation: engineValidation,
  apiErrors: ApiErrors,
  quoteApplication: QuoteApplication,
  submitApplication: SubmitApplication,
  address: Address,
  errorPage: ErrorPage,
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  back: 'Back',
  arrowBack: 'Back arrow',
  arrowForward: 'Forward arrow',
  editResponse: 'Edit response',
  error: 'Error',
  logo: '{{companyName}} logo',
  pleaseAnswer: 'Please answer the question first',
  phoneNumber: 'Phone Number',
  search: 'Search',
  formats: {
    date: "D MMM 'YY",
    time: 'H:mm',
    at: 'at',
  },
  scheduleCall: ScheduleCall,
  languages: {
    [Language.en]: 'Anglais',
    [Language.fr]: 'Français',
  },
  languagesCompact: {
    [Language.en]: 'EN',
    [Language.fr]: 'FR',
  },
  contactConfirmation: ContactConfirmation,
  home: Home,
  needToTalkButton: 'Need to talk?',
  transitionLoadingPage: {
    title: 'We are calculating...',
    subtitle: 'Please stand by for a few seconds while we calculate the right coverage amount for you.',
  },
  steps: Steps,
  blocking: Blocking,
  payment: Payment,
};
