import _ from 'lodash';
import { SetStateAction, Dispatch, useCallback, useContext, useEffect, useState } from 'react';

import {
  getAllSubsections,
  isRenderingQuestionnaireComplete,
  OnAnswerChange,
  QuestionnaireEngine,
  RenderingQuestionnaire,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { EngineEffects, RenderingType, VersionedAnswers } from '@breathelife/types';

import { CarrierContext } from '../Context/CarrierContext';
import { shortLocale, text } from '../Localization/Localizer';
import { RenderingStep } from '../Models/Step';

type StepProps = {
  setLocalVersionedAnswers: Dispatch<SetStateAction<VersionedAnswers>>;
  localVersionedAnswers: VersionedAnswers;
  setSubmitFormRequested: Dispatch<SetStateAction<boolean>>;
  renderingQuestionnaire: RenderingQuestionnaire;
  renderingStep: RenderingStep;
  /**
   * Attempt to submit the answer for the current step.
   * Returns `true` if the submission is successful. `false` otherwise
   */
  onSubmit: () => boolean;
  onAnswerChange: OnAnswerChange;
};

export function useStep(
  questionnaireEngine: QuestionnaireEngine,
  displayErrors: boolean,
  submitAnswers: (versionedAnswers: VersionedAnswers) => void,
  versionedAnswers: VersionedAnswers,
  setShouldRefreshApplicationPremium?: (shouldRefreshApplicationPremium: boolean) => void,
): StepProps {
  const { features } = useContext(CarrierContext);

  const [localVersionedAnswers, setLocalVersionedAnswers] = useState<VersionedAnswers>(
    questionnaireEngine.getVersionedAnswersWithDefaultValues(versionedAnswers),
  );

  const [displayValidationErrors, setDisplayValidationErrors] = useState(displayErrors);
  const [submitFormRequested, setSubmitFormRequested] = useState(false);

  const language = shortLocale();

  const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(
    localVersionedAnswers,
    language,
    text,
    {
      renderingType: RenderingType.web,
      shouldValidateAllAnswers: displayValidationErrors,
    },
  );

  const onSubmit = useCallback(() => {
    const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(
      localVersionedAnswers,
      language,
      text,
      {
        renderingType: RenderingType.web,
        shouldValidateAllAnswers: displayValidationErrors,
      },
    );

    if (!isRenderingQuestionnaireComplete(renderingQuestionnaire)) {
      setDisplayValidationErrors(true);
      return false;
    }

    submitAnswers(localVersionedAnswers);
    return true;
  }, [
    localVersionedAnswers,
    language,
    questionnaireEngine,
    submitAnswers,
    displayValidationErrors,
    setDisplayValidationErrors,
  ]);

  // Handle delayed submit actions from callbacks that depend on state changes to localAnswers object
  useEffect(() => {
    if (!submitFormRequested) return;
    onSubmit();
    setSubmitFormRequested(false);
  }, [submitFormRequested, setSubmitFormRequested, onSubmit]);

  const allSteps: RenderingStep[] = getAllSubsections(renderingQuestionnaire);
  const renderingStep = _.first(allSteps);
  if (!renderingStep) throw new Error('Rendering step not found');

  const onAnswerChange: OnAnswerChange = useCallback(
    (
      items: {
        id: string;
        value: any;
        effects?: EngineEffects;
        repeatedIndices?: RepeatedIndices;
        triggerStepNavigation?: boolean;
      }[],
    ): void => {
      setLocalVersionedAnswers((prevVersionedAnswers) => {
        let updatedVersionedAnswers = prevVersionedAnswers;

        for (const item of items) {
          updatedVersionedAnswers = questionnaireEngine.updateAnswer(
            prevVersionedAnswers,
            item.id,
            item.value,
            item.effects,
            item.repeatedIndices,
          );
        }

        return updatedVersionedAnswers;
      });

      if (features.pricing?.enabled && !!setShouldRefreshApplicationPremium) {
        const pricingNodeIds = features.pricing.enabled === true ? features.pricing.nodeIdsAffectingPricing : null;

        if (pricingNodeIds) {
          const isNodeIdAffectingPricing =
            Array.isArray(pricingNodeIds) && items.some((item) => pricingNodeIds.includes(item.id));

          if (isNodeIdAffectingPricing) {
            setShouldRefreshApplicationPremium(true);
          }
        }
      }

      if (items.some((item) => item.triggerStepNavigation === true)) {
        setSubmitFormRequested(true);
      }
    },
    [questionnaireEngine],
  );

  return {
    setLocalVersionedAnswers,
    localVersionedAnswers,
    setSubmitFormRequested,
    renderingQuestionnaire,
    renderingStep,
    onSubmit,
    onAnswerChange,
  };
}
