import { memo, ReactElement, useContext } from 'react';

import { logger } from '@breathelife/monitoring-frontend';
import { PaymentServiceProvider } from '@breathelife/types';

import { CarrierContext } from '../../Context/CarrierContext';
import { StripeViewContainer } from './ProviderViews/StripeView/StripeViewContainer';

type PaymentViewRendererProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
};

export const PaymentViewRenderer = memo(function Component({
  onNextClick,
  onPreviousClick,
}: PaymentViewRendererProps): ReactElement | null {
  const {
    features: { payments },
  } = useContext(CarrierContext);

  if (!payments || !payments.enabled) {
    const err = new Error('Payments is not enabled.');
    logger.error(err);
    throw err;
  }

  const provider = payments.serviceProvider;

  const paymentProviderComponentMap: { [key in PaymentServiceProvider]: ReactElement | null } = {
    [PaymentServiceProvider.CLOVER]: null,
    [PaymentServiceProvider.STRIPE]: (
      <StripeViewContainer onNextClick={onNextClick} onPreviousClick={onPreviousClick} />
    ),
  };

  return paymentProviderComponentMap[provider] ?? null;
});
