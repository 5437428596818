import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { IconName, Language } from '@breathelife/types';
import { Image, SharedTheme, Text, Icon } from '@breathelife/ui-components';

import { ScheduleCallTabsContainer } from '../../Components/ScheduleCallTabs/ScheduleCallTabsContainer';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { text } from '../../Localization/Localizer';

type Props = {
  applicationAssigneeImage?: string;
  language: Language;
};

function LayoutImage(props: { assigneeImage?: string }): ReactElement {
  const { assigneeImage } = props;
  const theme = useTheme() as SharedTheme;

  if (assigneeImage) {
    return (
      <Box width={60}>
        <Image src={assigneeImage} alt='' width={48} height={48} />
      </Box>
    );
  }

  return <Icon name={IconName.wait} size='60' color={{ primary: theme.colors.warning }} />;
}

export function BlockingView(props: Props): ReactElement {
  const layoutImage = <LayoutImage assigneeImage={props.applicationAssigneeImage} />;

  return (
    <CenteredLayout
      hideProgress
      hideScheduleCall
      isLoading={false}
      title={text('blocking.sorry')}
      offsetContent={layoutImage}
      headerVariant='default'
      subtitle=''
    >
      <Box mt={2}>
        <Text component='p'>{text('blocking.doNotQualify')}</Text>
        <Text component='p'>{text('blocking.getInTouch')}</Text>
      </Box>
      <Box mt={4}>
        <ScheduleCallTabsContainer
          language={props.language}
          localizationStrings={{
            [props.language]: {
              subtitle: {
                single: { callYou: '', callUs: '' },
                plural: { callYou: '', callUs: '' },
              },
              confirmation: {
                single: {
                  title: text('blocking.confirmation.title'),
                  subtitle: text('scheduleCall.modal.confirmation.single.subtitle'),
                },
                plural: {
                  title: text('blocking.confirmation.title'),
                },
              },
              submit: text('scheduleCall.submit'),
            },
          }}
        />
      </Box>
    </CenteredLayout>
  );
}
