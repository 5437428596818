import { Box } from '@breathelife/mui';
import styled from 'styled-components';

import { Text } from '@breathelife/ui-components';

export const CardWrapper = styled.div`
  & > * {
    border: 1px solid ${(props) => props.theme.colors.grey[40]};
    &:not(:last-child) {
      border-bottom: none;
  }
`;

export const Highlight = styled(Text)`
  & {
    color: ${(props) => props.theme.colors.grey[80]};
  }
`;

export const PremiumContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const LoaderOverlay = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const ButtonsWrapper = styled(Box)`
  & > * {
    flex: 1 1 50%;
  }
`;

export const Logo = styled.img`
  width: 68px;
  padding: 16px;
  height: auto;
`;
