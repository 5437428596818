import { Box } from '@breathelife/mui';
import styled from 'styled-components';

export const CarrierName = styled.p`
  font-size: ${(props) => props.theme.fonts.size.productPage.default};
  font-weight: bold;
  margin: unset;
`;

export const ProductText = styled.p`
  font-size: ${(props) => props.theme.fonts.size.productPage.default};
  color: ${(props) => props.theme.colors.grey[80]};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LogoContainer = styled(Box)`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    display: none;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    flex-direction: row;
  }
`;
