import styled from 'styled-components';

import { NextButton } from '@breathelife/ui-components';

export const StyledNextButton = styled(NextButton)`
  &&&&& {
    path {
      fill: none;
    }
  }
`;
