import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CryptoMaterial } from '@breathelife/crypto-material';
import { OutcomeCode } from '@breathelife/types';

export type SubmissionState = {
  isSubmissionStarted: boolean;
  isSubmissionSuccess: boolean;
  isSubmissionResolved: boolean;
  outcome?: OutcomeCode;
  policyNumber?: string;
  cryptoMaterial?: CryptoMaterial;
  errorStep?: ErrorStep;
  vendorCode?: string;
};

const defaultState = {
  isSubmissionStarted: false,
  isSubmissionSuccess: false,
  isSubmissionResolved: false,
};

export enum ErrorStep {
  decision = 'decision',
  submission = 'submission',
  signature = 'signature',
  crypto = 'crypto',
}

type ErrorStepPayload = {
  errorStep?: ErrorStep;
};

type IsStartedPayload = {
  isSubmissionStarted: boolean;
};

type IsSuccessPayload = {
  isSubmissionSuccess: boolean;
};

type DecisionPayload = {
  outcome: OutcomeCode;
};

type CryptoPayload = {
  cryptoMaterial: CryptoMaterial;
};

type SignResponsePayload = {
  policyNumber: string;
};

type EventPayload = {
  outcome: OutcomeCode;
  vendorCode: string;
};

type ActionsType = {
  setIsStarted: (state: SubmissionState, { payload }: PayloadAction<IsStartedPayload>) => void;
  setIsSuccess: (state: SubmissionState, { payload }: PayloadAction<IsSuccessPayload>) => void;
  setSignResponse: (state: SubmissionState, { payload }: PayloadAction<SignResponsePayload>) => void;
  setCryptoMaterial: (state: SubmissionState, { payload }: PayloadAction<CryptoPayload>) => void;
  setDecisionOutcome: (state: SubmissionState, { payload }: PayloadAction<DecisionPayload>) => void;
  setErrorStep: (state: SubmissionState, { payload }: PayloadAction<ErrorStepPayload>) => void;
  createDataLayerEvent: (state: SubmissionState, { payload }: PayloadAction<EventPayload>) => void;
  reset: (state: SubmissionState) => void;
};

export const submissionSlice = createSlice<SubmissionState, ActionsType>({
  name: 'consumerFlow/submission',
  initialState: defaultState,
  reducers: {
    setIsStarted(state: SubmissionState, { payload }: PayloadAction<IsStartedPayload>) {
      state.isSubmissionStarted = payload.isSubmissionStarted;
    },
    setIsSuccess(state: SubmissionState, { payload }: PayloadAction<IsSuccessPayload>) {
      state.isSubmissionSuccess = payload.isSubmissionSuccess;
      state.isSubmissionResolved = true;
    },
    setErrorStep(state: SubmissionState, { payload }: PayloadAction<ErrorStepPayload>) {
      state.errorStep = payload.errorStep;
      state.isSubmissionResolved = true;
    },
    setSignResponse(state: SubmissionState, { payload }: PayloadAction<SignResponsePayload>) {
      state.policyNumber = payload.policyNumber;
    },
    setDecisionOutcome(state: SubmissionState, { payload }: PayloadAction<DecisionPayload>) {
      state.outcome = payload.outcome;
    },
    setCryptoMaterial(state: SubmissionState, { payload }: PayloadAction<CryptoPayload>) {
      state.cryptoMaterial = payload.cryptoMaterial;
    },
    createDataLayerEvent(state: SubmissionState, { payload }: PayloadAction<EventPayload>) {
      state.outcome = payload.outcome;
      state.vendorCode = payload.vendorCode;
    },
    reset(state: SubmissionState) {
      state.isSubmissionStarted = false;
      state.isSubmissionSuccess = false;
      state.isSubmissionResolved = false;
      state.outcome = undefined;
      state.policyNumber = undefined;
      state.cryptoMaterial = undefined;
      state.errorStep = undefined;
    },
  },
});
