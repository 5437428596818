export default {
  birthDate: {
    max: 'The age entered is above the maximum allowed.',
    min: 'The age entered is below the minimum required.',
  },
  numberMultiple: {
    '10': 'The amount must be a multiple of 10.',
    '1000': 'The amount must be a multiple of 1000.',
  },
  policyholder: {
    max: 'Another insured is already the policyholder. Please select only one.',
    min: 'At least one insured must be selected as the policyholder',
  },
  required: 'Required field',
  sin: 'Please enter a valid social insurance number',
  sinLength: 'Please enter a social insurance number with 9 digits',
  ssnLength: 'Please enter a valid social security number with 9 digits(e.g: 123-45-6789)',
  canadianPostalCode: 'The postal code needs to be in the following format: A1A 1A1 or A1A1A1',
  zipCode: 'The Zip code needs to be in the following format: 12345 or 12345-1234',
  phoneNumber: 'Please enter a valid phone number with 10 digits',
  phoneNumberAreaCode: 'Please enter a valid area code',
  dateFormat: 'Please enter a valid date',
  pastDate: 'Please enter a date earlier than today',
  pastYear: 'Please enter a date in the last year',
  futureDate: 'Please enter a date later than today',
  futureOrCurrentDate: 'Please enter a current or future date',
  pastOrCurrentDate: 'Please enter a current or past date',
  currentDate: 'You must enter the current date',
  yearMonth: 'Please enter a date with the format YYYY-MM',
  decimal: 'Please enter a number (with up to 2 decimals)',
  quoteShare: 'Share values should add up to 100%',
  optional: 'Optional',
  radioCheckbox: 'Please answer the question',
  isTrue: 'Please accept the terms',
  agreeField: 'Please read and accept the notice',
  matchEmail: 'Email addresses do not match',
  invalidFirstName: 'Invalid first name',
  invalidLastName: 'Invalid last name',
  invalidMiddleName: 'Invalid middle name',
  invalidFullName: 'Invalid full name',
  invalidEmail: 'Invalid email',
  number: {
    min: 'The minimum is %{min}',
    max: 'The maximum is %{max}',
  },
  accountNumber: 'Please enter a valid account number with at least 7 digits',
  institutionNumber: 'Please enter a valid institution code with 3 digits',
  branchNumber: 'Please enter a valid transit code with 5 digits',
};
