import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import styled, { useTheme } from 'styled-components';

import { SelectedProductInformation } from '@breathelife/types';
import { Loader, SharedTheme, Text } from '@breathelife/ui-components';

import { Button } from '../../Components/Button';
import { CurrencyText } from '../../Components/CurrencyText/CurrencyText';
import { text } from '../../Localization/Localizer';
import { LegacySelectedProductInformation } from '../../Models/Product';
import { ProductCard } from '../ProductCard/ProductCard';

type Props = {
  productInformation?: LegacySelectedProductInformation | SelectedProductInformation;
  monthlyPremium?: number;
  onChangeAnswer: () => void;
  onContinue: () => void;
  isNextBtnLoading: boolean;
  nextStepButtonText?: string;
};

const ActionButton = styled(Button)`
  && {
    margin-bottom: 16px;
    padding: 16px 24px;
  }
`;

export function PriceUpdateStep(props: Props): ReactElement | null {
  const theme = useTheme() as SharedTheme;

  const { productInformation, monthlyPremium, nextStepButtonText, isNextBtnLoading } = props;

  return (
    <Fragment>
      <Text>{text('steps.priceChanged')}</Text>
      {productInformation && <ProductCard product={productInformation} productInformationOnly />}
      {!productInformation && <MonthlyPremiumText premium={monthlyPremium} />}
      <Box mt={4}>
        <ActionButton
          data-testid='confirm-price'
          color='primary'
          size='large'
          variant='contained'
          minWidth={160}
          fullWidth
          onClick={props.onContinue}
        >
          {isNextBtnLoading ? (
            <Loader size='24px' color={theme.colors.grey[0]} />
          ) : (
            nextStepButtonText || text('continue')
          )}
        </ActionButton>
        <ActionButton
          data-testid='change-answer'
          color='primary'
          size='large'
          variant='outlined'
          fullWidth
          onClick={props.onChangeAnswer}
        >
          {text('steps.changeAnswer')}
        </ActionButton>
      </Box>
    </Fragment>
  );
}

function MonthlyPremiumText({ premium }: { premium?: number }): ReactElement {
  return typeof premium !== 'undefined' ? (
    <CurrencyText amount={premium} suffix={text('product.perMonth')} />
  ) : (
    <Text>{text('product.NA')}</Text>
  );
}
