export default {
  advisorGreeting: "Hi, I'm {{advisorName}}. ",
  interlocutorGreeting: 'Hi, we are {{interlocutorName}}',
  startFlow: "Let's get started",
  advisorTitle: 'Financial advisor',
  life: {
    welcomeTextTitle: "Let's find the right life insurance plan for you.",
    welcomeTextSubtitle: {
      single:
        "Let's take the next few minutes to answer some questions together so that I can suggest the right financial protection for your family’s peace of mind.",
      plural:
        "Let's take the next few minutes to answer some questions together so that we can suggest the right financial protection for your family’s peace of mind.",
    },
  },
};
