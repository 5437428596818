import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useContext } from 'react';

import { QuotedInsuranceProduct } from '@breathelife/types';
import { List, ListItem, Loader, Text, Title } from '@breathelife/ui-components';

import { Button } from '../../Components/Button';
import { CurrencyText } from '../../Components/CurrencyText/CurrencyText';
import { CarrierContext } from '../../Context/CarrierContext';
import { text } from '../../Localization/Localizer';
import { LegacyQuotedProduct } from '../../Models/Product';
import { ButtonsWrapper, CardWrapper, Highlight, LoaderOverlay, Logo, PremiumContainer } from './Styles';

type Props = {
  product: LegacyQuotedProduct | QuotedInsuranceProduct;
  isSelfServeBlocked?: boolean;
  isLoadingQuotes?: boolean;
  onOpenDetails?: (product: LegacyQuotedProduct | QuotedInsuranceProduct) => void;
  onApply?: (product: LegacyQuotedProduct | QuotedInsuranceProduct) => void;
  onApplyThroughAdvisor?: (productId: string) => void;
  productInformationOnly?: boolean;
};

export function ProductCard(props: Props): ReactElement {
  const { onOpenDetails, product, isLoadingQuotes, onApplyThroughAdvisor, onApply, productInformationOnly } = props;

  const { enableLoadProductInformationFromDb } = useContext(CarrierContext);

  const onOpenDetailsClick = useCallback(() => {
    onOpenDetails?.(product);
  }, [onOpenDetails, product]);

  if (enableLoadProductInformationFromDb) {
    const insuranceProduct = product as QuotedInsuranceProduct;
    const { id: productId, logoUrl, additionalFeatures, name, premium } = insuranceProduct;

    return (
      <CardWrapper>
        <Box display='flex'>
          <Box p={2}>
            <Logo src={logoUrl} alt='' />
          </Box>
          <Box p={2}>
            <Box mb={1}>
              <Title level={4}>{name}</Title>
            </Box>
            <List noSpacing>
              {Array.isArray(additionalFeatures) &&
                additionalFeatures.map((additionalInformation) => (
                  <ListItem key={additionalInformation} iconType='checkmark'>
                    <Highlight noSpacing variant='legal'>
                      {additionalInformation}
                    </Highlight>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
        <PremiumContainer position='relative'>
          {isLoadingQuotes && (
            <LoaderOverlay position='absolute' display='flex' justifyContent='center' flexDirection='column'>
              <Loader />
            </LoaderOverlay>
          )}
          <Box display='flex' justifyContent='center' p={2} my={1}>
            {premium ? (
              <CurrencyText amount={premium} suffix={text('product.perMonth')} />
            ) : (
              <Text>{text('product.NA')}</Text>
            )}
          </Box>
          {!productInformationOnly && onApplyThroughAdvisor && onApply && onOpenDetails && (
            <ButtonsWrapper p={2} pt={0} display='flex'>
              <Button disabled={isLoadingQuotes} color='primary' size='large' onClick={onOpenDetailsClick}>
                {text('product.detailsButtonLabel')}
              </Button>
              {props.isSelfServeBlocked ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={() => onApplyThroughAdvisor(productId)}
                >
                  {text('product.getInTouch')}
                </Button>
              ) : (
                <Button
                  data-testid='apply'
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={!premium || isLoadingQuotes}
                  onClick={() => {
                    if (!premium) return;
                    onApply(insuranceProduct);
                  }}
                >
                  {text('product.applyButtonLabel')}
                </Button>
              )}
            </ButtonsWrapper>
          )}
        </PremiumContainer>
      </CardWrapper>
    );
  } else {
    const insuranceProduct = product as LegacyQuotedProduct;
    const { id: productId, name, shortName, premium, highlights } = insuranceProduct;
    // TODO: FIXME: If we are to have another carrier using product not from db, this will need to be configurable through the tooling.
    const productLogoSrc = 'https://placehold.co/600x400';
    return (
      <CardWrapper>
        <Box display='flex'>
          <Box p={2}>
            <Logo src={productLogoSrc} alt='' />
          </Box>
          <Box p={2}>
            <Box mb={1}>
              <Title level={4}>{shortName || name}</Title>
            </Box>
            {highlights && (
              <List noSpacing>
                {highlights.map((highlight) => (
                  <ListItem key={highlight.title} iconType={'checkmark'}>
                    <Highlight noSpacing variant='legal'>
                      {highlight.title}
                    </Highlight>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>

        <PremiumContainer position='relative'>
          {isLoadingQuotes && (
            <LoaderOverlay position='absolute' display='flex' justifyContent='center' flexDirection='column'>
              <Loader />
            </LoaderOverlay>
          )}
          <Box display='flex' justifyContent='center' p={2} my={1}>
            {premium ? <CurrencyText amount={premium} suffix={text('product.perMonth')} /> : text('product.NA')}
          </Box>
          {!productInformationOnly && onApplyThroughAdvisor && onApply && onOpenDetails && (
            <ButtonsWrapper p={2} pt={0} display='flex'>
              <Button disabled={isLoadingQuotes} color='primary' size='large' onClick={onOpenDetailsClick}>
                {text('product.detailsButtonLabel')}
              </Button>
              {props.isSelfServeBlocked ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={() => onApplyThroughAdvisor(productId)}
                >
                  {text('product.getInTouch')}
                </Button>
              ) : (
                <Button
                  data-testid='apply'
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={!premium || isLoadingQuotes}
                  onClick={() => {
                    if (!premium) return;
                    onApply(insuranceProduct);
                  }}
                >
                  {text('product.applyButtonLabel')}
                </Button>
              )}
            </ButtonsWrapper>
          )}
        </PremiumContainer>
      </CardWrapper>
    );
  }
}
