export default {
  loading: {
    title: 'Processing...',
    body: 'Please wait while we process your request.',
  },
  error: {
    title: 'Sorry',
    body: `<p>Your application could not be submitted.</p>
    <p>Tell us the most convenient time to call you in the next 48 business hours. An agent will help you submit your application.</p>`,
  },
  responses: {
    accepted: {
      title: 'Your application has been submitted successfully!',
      body: `<p>Final validations are required to confirm the acceptance of your application.</p>
      <p>You will receive a confirmation of the decision by mail within 5 to 10 business days. The policy will take effect only when you receive this confirmation.</p>
      <p>You are covered while we are analyzing your application.</p>`,
    },
    referred: {
      title: 'Your application has been submitted successfully!',
      body: `<p>Your application has been submitted to one of our analysts who may call you to obtain additional information.</p>
        <p>After analyzing the information provided, a confirmation of the decision will be sent to you by mail. The policy will take effect only when you receive this confirmation.</p>
        <p>You are covered while we are analyzing your application.</p>`,
    },
    conditional: {
      title: 'Your application has been submitted successfully!',
      body: 'Your life insurance application has been conditionally accepted subject to a review by one of our experts. We will confirm our decision within 48 hours. During this period, your life insurance is in force, under certain conditions.',
    },
    denied: {
      title: 'Your application was declined',
      body: 'Unfortunately, we regret to inform you that we cannot respond favorably to your application due to some answers provided in the questionnaire.',
    },
  },
  download: {
    temporaryInsurance:
      'For more information, <a href="%{url}" target="_blank" noopener noreferrer>download</a> the temporary insurance amendment.',
    application: '<a href="%{url}" target="_blank" noopener noreferrer>Download</a> your Application',
  },
  confirmationNumber: 'Confirmation number for your records:',
  confirmationCryptoSignature: 'Confirmation Crypto Signature:',
  contactInfo: {
    moreInfo:
      'For any questions, modifications or to cancel your application, please contact one of our representatives at <a href="tel:{{phoneNumber}}">{{phoneNumber}}</a>',
    or: 'or',
  },
};
