import { SchedulingTimeFrameConfig, SchedulingTimeFrames, TimeOfDay } from '@breathelife/ui-components';

import { text } from '../Localization/Localizer';

export function getSchedulingTimeFrames(schedulingTimeFrameConfig?: SchedulingTimeFrameConfig): SchedulingTimeFrames {
  return schedulingTimeFrameConfig
    ? buildSchedulingTimeFramesFromConfig(schedulingTimeFrameConfig)
    : getDefaultSchedulingTimeFrames();
}

function buildSchedulingTimeFramesFromConfig(config: SchedulingTimeFrameConfig): SchedulingTimeFrames {
  const schedulingTimeFrames: SchedulingTimeFrames = {};

  for (const timeOfDay of Object.keys(config)) {
    const timeFrame = config[timeOfDay as TimeOfDay];
    if (!timeFrame) continue;

    schedulingTimeFrames[timeOfDay as TimeOfDay] = {
      timeFrame,
      description: text(`product.${timeOfDay}`),
    };
  }

  return schedulingTimeFrames;
}

export function getDefaultSchedulingTimeFrames(): SchedulingTimeFrames {
  return {
    morning: { timeFrame: [9, 12], description: text('product.morning') },
    day: { timeFrame: [12, 18], description: text('product.day') },
    evening: { timeFrame: [18, 21], description: text('product.evening') },
  };
}
