import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SchedulingTimeFrameConfig } from '@breathelife/ui-components';

export type ConfigurationState = {
  landingStepsIds: string[];
  lastStepsIds: string[];
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
};

const defaultState = {
  landingStepsIds: [],
  lastStepsIds: [],
};

type ConfigurationPayload = {
  lastStepsIds: string[];
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
};

type ActionsType = {
  setConfigurations: (state: ConfigurationState, { payload }: PayloadAction<ConfigurationPayload>) => void;
  setLandingStepIds: (state: ConfigurationState, { payload }: PayloadAction<{ landingStepsIds: string[] }>) => void;
};

export const configurationSlice = createSlice<ConfigurationState, ActionsType>({
  name: 'consumerFlow/configuration',
  initialState: defaultState,
  reducers: {
    setConfigurations(state: ConfigurationState, { payload }: PayloadAction<ConfigurationPayload>) {
      state.lastStepsIds = payload.lastStepsIds;
      state.schedulingTimeFrames = payload.schedulingTimeFrames;
    },
    setLandingStepIds(state: ConfigurationState, { payload }: PayloadAction<{ landingStepsIds: string[] }>) {
      state.landingStepsIds = payload.landingStepsIds;
    },
  },
});
