import { ReactElement, useCallback, useMemo, useContext, useState, useRef, FormEvent } from 'react';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import { StripeFormRefHandle, StripeProvider, useStripeContext } from '@breathelife/payments-ui';

import { useCxSelector } from '../../../../Hooks/useCxSelector';
import { StripeView } from './StripeView';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { shortLocale, text } from '../../../../Localization/Localizer';
import {
  useCreatePaymentTokenMutation,
  usePatchPaymentTokenMutation,
} from '../../../../ReactQuery/Payments/paymentTransaction.mutations';
import { useFetchPaymentTransaction } from '../../../../ReactQuery/Payments/paymentTransaction.queries';
import { notificationSlice } from '../../../../Redux/Notification/NotificationSlice';

type StripeViewContainerProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
};

type ContainerProps = {
  applicationId: string;
} & StripeViewContainerProps;

type RefetchControl = {
  count: number;
  isLimitReached: boolean;
};

const maxRefetchCount = 3;
const defaultRefetchControl: RefetchControl = { count: 0, isLimitReached: false };
const recreateSetupStatuses: string[] = ['requires_confirmation', 'requires_action', 'canceled'];
const refetchPaymentTransactionStatuses: string[] = ['processing'];
const successfulStripeSubmissionStatuses: string[] = ['succeeded'];

function Container({ applicationId, onNextClick, onPreviousClick }: ContainerProps): ReactElement | null {
  const { stripe } = useStripeContext();
  const dispatch = useDispatch();

  const formRef = useRef<StripeFormRefHandle>(null);
  const refetchControl = useRef<RefetchControl>({ ...defaultRefetchControl });

  const [tempClientSecret, setTempClientSecret] = useState<string | null>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [hasFormSubmitted, setHasFormSubmitted] = useState<boolean>(false);
  const [isSkipping, setIsSkipping] = useState<boolean>(false);

  const { mutate: createPaymentToken, isLoading: isCreatePaymentTokenLoading } = useCreatePaymentTokenMutation();
  const { mutate: patchPaymentToken } = usePatchPaymentTokenMutation();

  const clearRefetchControl = (): void => {
    refetchControl.current = { ...defaultRefetchControl };
  };

  const generateTemporaryClientSecret = useCallback(async () => {
    return await createPaymentToken(
      { data: { applicationId }, shouldInvalidateFetchQuery: false },
      {
        onSuccess: (paymentTransaction) => {
          setTempClientSecret(paymentTransaction?.paymentToken?.clientSecret);
        },
      },
    );
  }, [applicationId, createPaymentToken]);

  const {
    data: paymentTransaction,
    isLoading: isFetchPaymentTransactionLoading,
    isRefetching: isFetchPaymentTransactionRefetching,
  } = useFetchPaymentTransaction(
    applicationId,
    { withCardDetails: true, withToken: true },
    {
      onError: (err) => {
        logger.error(err);
        dispatch(
          notificationSlice.actions.setError({
            message: text('payment.loadFormError'),
          }),
        );
      },
      onSuccess: async (paymentTransaction) => {
        if (!paymentTransaction) {
          return await createPaymentToken({ data: { applicationId } });
        }

        if (recreateSetupStatuses.includes(paymentTransaction?.paymentToken?.status ?? '')) {
          // A payment method can only be added if the setup intent status is `requires_payment_method`
          // This workflow does not support 'requires_action' or `requires_confirmation', however,
          // these statuses should still be accounted for
          // See – https://stripe.com/docs/payments/intents#intent-statuses
          clearRefetchControl();
          return await generateTemporaryClientSecret();
        }

        if (successfulStripeSubmissionStatuses.includes(paymentTransaction.paymentToken?.status ?? '')) {
          // Skip to submission if the setup has already succeeded
          setHasFormSubmitted(true);
          setIsSkipping(true);
          onNextClick();
          return;
        }

        if (refetchPaymentTransactionStatuses.includes(paymentTransaction?.paymentToken?.status ?? '')) {
          // If the payment method is still in a state of "processing", 3 attempts will be made to re-fetch
          // the transaction to see if it's state has changed
          const newCount = refetchControl.current.count + 1;
          refetchControl.current = {
            count: newCount,
            isLimitReached: newCount >= maxRefetchCount,
          };

          if (!refetchControl.current.isLimitReached) return;
          // If the limit is reached, we  stop refetching and show an error notification.

          logger.error(
            `Max Retries reached: Stripe setup intent for payment transaction: ${paymentTransaction.id} is still in 'processing' state`,
          );

          dispatch(
            notificationSlice.actions.setError({
              message: text('payment.savePaymentError'),
            }),
          );

          return;
        }

        clearRefetchControl();
      },
      refetchOnMount: 'always',
      refetchInterval: (paymentTransaction) => {
        const isRefetchCriteriaMet = refetchPaymentTransactionStatuses.includes(
          paymentTransaction?.paymentToken?.status ?? '',
        );
        return isRefetchCriteriaMet && !refetchControl.current.isLimitReached ? 3000 : false;
      },
    },
  );

  const clientSecretToUse = useMemo<string | null>(() => {
    if (!paymentTransaction?.paymentToken) {
      return null;
    }

    const clientSecret = tempClientSecret ?? paymentTransaction?.paymentToken?.clientSecret;

    if (!clientSecret) {
      // If this block is reached, the error is fatal
      // A payment transaction exists but has no client secret and no temporary client secret has been created
      // Due to this, the Stripe form is useless as it requires a client secret
      logger.error(
        `Unable to find a Stripe client secret - Neither a stored nor temporary client secret could be found \n\n Application ID: ${paymentTransaction.applicationId} \n\n Transaction ID: ${paymentTransaction.id}`,
      );
      dispatch(
        notificationSlice.actions.setError({
          message: text('payment.loadFormError'),
        }),
      );
    }

    return clientSecret;
  }, [paymentTransaction, tempClientSecret, dispatch]);

  const onSubmit = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    if (isFormSubmitting || hasFormSubmitted || !stripe || !paymentTransaction) {
      return;
    }

    setHasFormSubmitted(false);
    setIsFormSubmitting(true);

    await formRef.current?.onSubmit({
      onSubmitFailure: (error) => {
        logger.error(error);

        setIsFormSubmitting(false);
        dispatch(
          notificationSlice.actions.setError({
            message: text('payment.submitPaymentError'),
          }),
        );
      },
      onSubmitValidationFailure: () => setIsFormSubmitting(false),
      onSubmitSuccess: async ({ id: paymentToken, status = '' }) => {
        const isStatusValid = successfulStripeSubmissionStatuses.includes(status);

        setHasFormSubmitted(isStatusValid);
        setIsFormSubmitting(false);

        if (!isStatusValid) {
          dispatch(
            notificationSlice.actions.setError({
              message: text('payment.submitPaymentError'),
            }),
          );
          return;
        }

        if (!tempClientSecret) {
          onNextClick();
          return;
        }

        const { id: paymentTransactionId } = paymentTransaction;

        return await patchPaymentToken(
          { applicationId, data: { paymentToken }, paymentTransactionId },
          {
            onSettled: async (_, err) => {
              if (err) {
                logger.error(err);
                dispatch(
                  notificationSlice.actions.setError({
                    message: text('payment.savePaymentError'),
                  }),
                );
                await generateTemporaryClientSecret();
                setHasFormSubmitted(false);

                return;
              }

              setTempClientSecret(null);
              onNextClick();
            },
          },
        );
      },
    });
  };

  const isLoading =
    isFetchPaymentTransactionLoading ||
    isFetchPaymentTransactionRefetching ||
    refetchPaymentTransactionStatuses.includes(paymentTransaction?.paymentToken?.status ?? '') ||
    (!paymentTransaction && isCreatePaymentTokenLoading) ||
    !clientSecretToUse ||
    isSkipping;

  return (
    <StripeView
      isLoading={isLoading}
      isSubmitting={isFormSubmitting}
      hasSubmitted={hasFormSubmitted}
      clientSecret={clientSecretToUse}
      formRef={formRef}
      onSubmit={onSubmit}
      onPreviousClick={onPreviousClick}
    />
  );
}

export function StripeViewContainer({ onNextClick, onPreviousClick }: StripeViewContainerProps): ReactElement | null {
  const { features } = useContext(CarrierContext);
  const language = shortLocale();
  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);

  if (!features.payments.enabled) {
    logger.error('Attempting to render Stripe payments form when payments are not enabled');
    return null;
  }

  if (!features.payments.stripe) {
    logger.error('Attempting to render Stripe payments without Stripe configuration');
    return null;
  }

  if (!insuranceApplication) {
    logger.error('Attempting to render Stripe payments without an application');
    return null;
  }

  return (
    <StripeProvider config={features.payments.stripe} language={language}>
      <Container applicationId={insuranceApplication.id} onNextClick={onNextClick} onPreviousClick={onPreviousClick} />
    </StripeProvider>
  );
}
