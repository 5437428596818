import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';

import * as DebugToolbarService from '../../Services/DebugToolbarService';
import { insuranceApplicationSlice } from '../InsuranceApplication/InsuranceApplicationSlice';
import { notificationSlice } from '../Notification/NotificationSlice';
import { navigationSlice } from '@breathelife/redux';
import { generateQuestionWithStepIdUrl } from '../../Navigation/Urls';

const { actions } = insuranceApplicationSlice;

export function updateAnswersUntil(applicationId: string, subsectionId: string, navigate: NavigateFunction) {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch(insuranceApplicationSlice.actions.setIsLoading(true));

    try {
      const response = await DebugToolbarService.updateApplicationWithDebugToolbar(applicationId, {
        subsectionId,
      });
      dispatch(actions.setInsuranceApplication({ insuranceApplication: response.application }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: `Application filled up to '${subsectionId}' step.`,
          autoHideDuration: 8000,
        }),
      );

      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      const url = generateQuestionWithStepIdUrl(subsectionId);
      navigate(url);
    } catch (err: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: err.response?.message,
        }),
      );
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
}
