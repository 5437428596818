import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { List, ListItem, Text, Title } from '@breathelife/ui-components';

import { RepeatableSection } from '../../../Models/Product';
import { Content, SectionContainer, SectionsContainer } from './Styles';

type RepeatableSectionsProps = {
  repeatableSections: RepeatableSection[];
};

export function RepeatableSections(props: RepeatableSectionsProps): ReactElement {
  const { repeatableSections } = props;
  return (
    <SectionsContainer py={3}>
      {repeatableSections.map((repeatableSection) => (
        <SectionContainer key={repeatableSection.title} mb={3} pb={3}>
          <Content px={3}>
            <Box mb={1.5}>
              <Title level={2} variant={4}>
                {repeatableSection.title}
              </Title>
            </Box>

            <List noSpacing>
              {repeatableSection.subsections.map((subsection) => (
                <ListItem key={subsection.title} iconType={'checkmark'}>
                  <Box mb={1}>
                    <Text noSpacing variant='legal' grey={90}>
                      {subsection.title}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Content>
        </SectionContainer>
      ))}
    </SectionsContainer>
  );
}
