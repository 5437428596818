import { Language } from '@breathelife/types';

import Address from './Address/french';
import ApiErrors from './ApiErrors/french';
import Blocking from './Blocking/french';
import ContactConfirmation from './ContactConfirmation/french';
import engineValidation from './EngineValidation/french';
import ErrorPage from './ErrorPage/french';
import Header from './Header/french';
import Home from './HomePage/french';
import Payment from './Payment/french';
import Pdf from './Pdf/french';
import Product from './Products/french';
import QuoteApplication from './QuoteApplication/french';
import ScheduleCall from './ScheduleCall/french';
import SessionExpired from './SessionExpired/french';
import Steps from './Steps/french';
import SubmitApplication from './SubmitApplication/french';
import ValidationErrors from './ValidationErrors/french';
import { carrierSpecificStrings } from './carrierSpecificStrings';

export default {
  product: Product,
  sessionExpired: SessionExpired,
  ...carrierSpecificStrings,
  loading: 'téléchargement...',
  pdf: Pdf,
  leftPanel: {
    clientAge: '{{age}} ans',
    clientGender: {
      male: 'Homme',
      female: 'Femme',
    },
    clientSmoker: {
      true: 'Fumeur',
      false: 'Non Fumeur',
    },
  },
  allRightReserved: 'TOUS DROITS RÉSERVÉS {{year}}',
  date: {
    year: 'Année',
    month: 'Mois',
    day: 'Jour',
  },
  confirm: 'Confirmer',
  continue: 'Continuer',
  understand: 'Je comprends',
  header: Header,
  validationErrors: ValidationErrors,
  validation: engineValidation,
  apiErrors: ApiErrors,
  quoteApplication: QuoteApplication,
  submitApplication: SubmitApplication,
  address: Address,
  errorPage: ErrorPage,
  month: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
  },
  back: 'Revenir en arrière',
  arrowBack: 'Flèche arrière',
  arrowForward: "Flèche vers l'avant",
  editResponse: 'Modifier la réponse',
  error: 'Erreur',
  logo: 'Logo de {{companyName}}',
  pleaseAnswer: "Veuillez répondre à la question d'abord.",
  phoneNumber: 'Téléphone',
  search: 'Chercher',
  formats: {
    date: "D MMM 'YY",
    time: 'H:mm',
    at: 'à',
  },
  advisor: {
    title: 'Conseiller financier',
  },
  scheduleCall: ScheduleCall,
  languages: {
    [Language.en]: 'English',
    [Language.fr]: 'French',
  },
  languagesCompact: {
    [Language.en]: 'EN',
    [Language.fr]: 'FR',
  },
  contactConfirmation: ContactConfirmation,
  home: Home,
  needToTalkButton: 'Besoin de discuter ?',
  transitionLoadingPage: {
    title: 'Nous calculons...',
    subtitle:
      'Veuillez patienter quelques secondes pendant que nous calculons le montant de couverture qui vous convient.',
  },
  steps: Steps,
  blocking: Blocking,
  payment: Payment,
};
