export default {
  dateInvalid: 'Date invalide',
  enterValidEmail: 'Veuillez entrer une adresse courriel valide.',
  fieldRequired: 'Champ requis.',
  nameFormat: 'Veuillez entrer un nom valide',
  maxDigits: 'Maximum de {{length}} caractères',
  zipCodeInvalid: 'Please enter a a valid zip code\n(e.g 12345 or 12345-1234)',
  enterValidNumber: 'Veuillez entrer un numéro valide',
  dateCannotBeFuture: 'La date ne peut pas être dans le futur',
  enterFirstName: 'Veuillez entrer votre prénom',
  enterLastName: 'Veuillez entrer votre nom',
  enterStreetAddress: 'Veuillez entrer votre adresse',
  enterStreetName: 'Veuillez entrer votre rue',
  enterCity: 'Veuillez entrer votre ville',
  enterProvince: 'Veuillez choisir une province',
  enterPostalCode: 'Veuillez entrer un code postal valide',
  enterValidPhoneNumber: 'Please enter a valid phone number\n(e.g: 123-456-7890)',
  enterDateInFormat: 'Veuillez entrer votre date de naissance correspondant au format  {{format}}',
  invalidDateOfBirth: 'Veuillez vérifier la date de naissance',
  ageValidations:
    "L'assurance vie temporaire en ligne est offerte aux personnes âgées de 18 à 49 ans. Afin de bien répondre à vos besoins, [planifiez un appel avec nous.](?schedule-call=true)",
};
