import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Communication, CommunicationPreferences, UserInfo } from '../../Models/ApplicationAssignee';

const defaultState = {
  isLoading: false,
  hasAppliedThroughAdvisor: false,
};

export type CommunicationState = Communication & {
  hasAppliedThroughAdvisor: boolean;
};

type UserInfoPayload = UserInfo;

type CommunicationPreferencesPayload = CommunicationPreferences;

type ActionsType = {
  setCommunicationPreferences: (
    state: CommunicationState,
    { payload }: PayloadAction<CommunicationPreferencesPayload>,
  ) => void;
  setIsLoading: (state: CommunicationState, { payload }: PayloadAction<boolean>) => void;
  setUserInfo: (state: CommunicationState, { payload }: PayloadAction<UserInfoPayload>) => void;
  setHasAppliedThroughAdvisor: (state: CommunicationState, { payload }: PayloadAction<boolean>) => void;
};

export const communicationSlice = createSlice<CommunicationState, ActionsType>({
  name: 'consumerFlow/communication',
  initialState: defaultState,
  reducers: {
    setCommunicationPreferences(
      state: CommunicationState,
      { payload }: PayloadAction<CommunicationPreferencesPayload>,
    ) {
      state.preferences = payload;
    },
    setIsLoading(state: CommunicationState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setUserInfo(state: CommunicationState, { payload }: PayloadAction<UserInfoPayload>) {
      state.userInfo = payload;
    },
    setHasAppliedThroughAdvisor(state: CommunicationState, { payload }: PayloadAction<boolean>) {
      state.hasAppliedThroughAdvisor = payload;
    },
  },
});
