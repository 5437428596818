import { PaymentTransaction } from '@breathelife/types';

import ApiService from './ApiService';

export async function fetchPaymentTransaction(
  applicationId: string,
  options: {
    withCustomer?: boolean;
    withToken?: boolean;
    withCardDetails?: boolean;
  } = {},
): Promise<PaymentTransaction | null> {
  const response = await ApiService.fetchPaymentTransaction(applicationId, options);
  return response.data;
}

export async function createPaymentTransaction(data: { applicationId: string }): Promise<PaymentTransaction> {
  const response = await ApiService.createPaymentTransaction(data);
  return response.data;
}

export async function patchPaymentTransaction(
  paymentTransactionId: string,
  data: { paymentToken: string },
): Promise<PaymentTransaction> {
  const response = await ApiService.patchPaymentTransaction(paymentTransactionId, data);
  return response.data;
}
