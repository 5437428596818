import { Language } from '@breathelife/types';

import { Address } from './Address/addressType';
import { ApiErrors } from './ApiErrors/apiErrorsType';
import { Blocking } from './Blocking/blockingType';
import { ContactConfirmation } from './ContactConfirmation/contactConfirmationType';
import { EngineValidation } from './EngineValidation/EngineValidationType';
import { ErrorPage } from './ErrorPage/errorPageType';
import { Header } from './Header/headerType';
import { Home } from './HomePage/homeType';
import { Payment } from './Payment/paymentType';
import { Pdf } from './Pdf/pdtType';
import { Product } from './Products/productsType';
import { QuoteApplication } from './QuoteApplication/quoteApplicationType';
import { ScheduleCall } from './ScheduleCall/scheduleCallType';
import { SessionExpired } from './SessionExpired/sessionExpiredType';
import { Steps } from './Steps/stepsType';
import { SubmitApplication } from './SubmitApplication/submitApplicationType';
import { ValidationErrors } from './ValidationErrors/validationErrorsType';
import baseEnglish from './english';
import baseFrench from './french';

type CarrierSpecificStrings = {
  carrierName: string | null;
  legal: {
    footer: string | null;
  };
  errors: {
    appAlreadySubmitted: string | null;
    unexpected: string | null;
  };
  scheduleCall: {
    legal: string | null;
    customerServicePhoneNumber: string | null;
  };
};

type Translations = {
  address: Address;
  allRightReserved: string;
  apiErrors: ApiErrors;
  arrowBack: string;
  arrowForward: string;
  back: string;
  blocking: Blocking;
  carrierSpecificStrings?: CarrierSpecificStrings;
  confirm: string;
  contactConfirmation: ContactConfirmation;
  continue: string;
  date: {
    year: string;
    month: string;
    day: string;
  };
  editResponse: string;
  error: string;
  errorPage: ErrorPage;
  formats: {
    date: string;
    time: string;
    at: string;
  };
  header: Header;
  home: Home;
  languages: {
    [Language.en]: string;
    [Language.fr]: string;
  };
  languagesCompact: {
    [Language.en]: string;
    [Language.fr]: string;
  };
  leftPanel: {
    clientAge: string;
    clientGender: {
      male: string;
      female: string;
    };
    clientSmoker: {
      true: string;
      false: string;
    };
  };
  logo: string;
  loading: string;
  month: {
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;
  };
  needToTalkButton: string;
  pdf: Pdf;
  payment: Payment;
  phoneNumber: string;
  pleaseAnswer: string;
  product: Product;
  quoteApplication: QuoteApplication;
  scheduleCall: ScheduleCall;
  search: string;
  sessionExpired: SessionExpired;
  steps: Steps;
  submitApplication: SubmitApplication;
  transitionLoadingPage: {
    title: string;
    subtitle: string;
  };
  understand: string;
  validation: EngineValidation;
  validationErrors: ValidationErrors;
};

export type Dictionaries = { [language in Language]: Translations };

const baseTranslation: Dictionaries = {
  en: baseEnglish,
  fr: baseFrench,
};

export default baseTranslation;
