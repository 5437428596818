export default {
  numberMultiple: {
    '10': 'Le montant doit être un multiple de 10.',
    '1000': 'Le montant doit être un multiple de 1000.',
  },
  birthDate: {
    max: "L'âge saisi est au-dessus du maximum permis.",
    min: "L'âge saisi est inférieur au minimum permis.",
  },
  policyholder: {
    max: 'Un autre assuré est déjà preneur. Veuillez en sélectionner un seul.',
    min: 'Il faut choisir un assuré comme preneur.',
  },
  required: 'Champ requis',
  sin: "Veuillez saisir un numéro d'assurance sociale valide",
  sinLength: "Veuillez entrer un numéro d'assurance sociale à 9 chiffres",
  ssnLength: 'Veuillez entrer un numéro de sécurité sociale valide à 9 chiffres (par exemple : 123-45-6789)',
  canadianPostalCode: 'Le code postal doit être au format A1A 1A1 ou A1A1A1',
  zipCode: 'Le code Zip doit être au format 12345 ou 12345-1234',
  phoneNumber: 'Veuillez saisir un numéro de téléphone valide à 10 chiffres',
  phoneNumberAreaCode: 'Veuillez saisir un indicatif régional valide',
  dateFormat: 'Veuillez saisir une date valide',
  pastDate: "Veuillez saisir une date antérieure à aujourd'hui",
  pastYear: 'Veuillez saisir une date dans la dernière année',
  futureDate: "Veuillez saisir une date ultérieure à aujourd'hui",
  futureOrCurrentDate: "Merci d'entrer une date courante ou dans le future",
  pastOrCurrentDate: "Merci d'entrer une date courante ou dans le passé",
  currentDate: 'Vous devez entrer la date du jour',
  yearMonth: 'Veuillez saisir une date dans le format AAAA-MM',
  decimal: 'Veuillez saisir un nombre (maximum 2 décimales)',
  quoteShare: 'Le total des quotes-parts doit être 100%',
  optional: 'Facultatif',
  radioCheckbox: 'Veuillez répondre à la question',
  isTrue: "Veuillez accepter les conditions d'utilisation",
  agreeField: 'Veuillez lire et accepter le préavis',
  matchEmail: 'Les addresses courriel ne correspondent pas',
  invalidFirstName: 'Prénom invalide',
  invalidLastName: 'Nom de famille invalide',
  invalidMiddleName: 'Second prénom invalide',
  invalidFullName: 'Nom complet invalide',
  invalidEmail: 'Courriel invalide',
  number: {
    min: 'Le minimum est %{min}',
    max: 'Le maximum est %{max}',
  },
  accountNumber: "Veuillez saisir un numéro de compte valide d'au moins 7 chiffres",
  institutionNumber: "Veuillez saisir un code d'institution valide à 3 chiffres",
  branchNumber: 'Veuillez saisir un code de transit valide à 5 chiffres',
};
