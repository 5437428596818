export default {
  unexpected: 'An unexpected error occurred',
  retrieving: 'An error occurred while retrieving your application',
  fetchFirstQuestion: 'Failed to pre-fetch the first question',
  fetchStepNoApplication: 'Cannot retrieve the questions without an application',
  fetchQuotes: 'An error occurred while fetching quotes',
  fetchApplication: 'An error occurred while fetching the application',
  fetchApplicationAssignee: 'An error occurred while fetching the application assignee',
  fetchLandingStep: 'An error occured while retrieving the landing step',
};
