export default {
  unexpected: 'Une erreur inattendue est survenue',
  retrieving: "Erreur lors de la récupération de l'application",
  fetchFirstQuestion: 'Erreur lors de la récupération de la première question',
  fetchStepNoApplication: 'Impossible de récupérer les questions sans un application',
  fetchQuotes: 'Erreur lors de la récupération des estimations',
  fetchApplication: "Erreur lors de la récupération de l'application",
  fetchApplicationAssignee: "Erreur lors de la récupération du destinataire de l'application",
  fetchLandingStep: "Erreur lors de la récupération de la page d'accueil",
};
