import { Box } from '@breathelife/mui';
import styled from 'styled-components';

export const SectionCard = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 2px solid ${(props) => props.theme.colors.grey[30]};

  &:first-child {
    border-top: 2px solid ${(props) => props.theme.colors.grey[30]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    background-color: unset;
    border: none !important;
  }
`;
