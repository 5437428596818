import { Box } from '@breathelife/mui';
import styled from 'styled-components';

export const DetailsContainer = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.grey[30]};
`;

export const SectionsContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
`;

export const SectionContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const OptionsContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
`;

export const CenteredLayoutWrapper = styled.div`
  & {
    main {
      @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
        margin: 48px 0 0 0;
      }
    }
  }
`;

export const Content = styled(Box)`
  && {
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const OffsetLogoWrapper = styled.div`
  line-height: 0;
  border: 1px solid ${(props) => props.theme.colors.grey[30]};
  border-radius: 50%;
  display: inline-block;
  padding: 2px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    margin-left: 24px;
  }
`;

export const OffsetLogo = styled.img`
  && {
    width: 54px;
    height: 54px;
    padding: 7px;
  }
`;

export const PolicyDetailsContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
`;
