import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

import { LoadingContent } from './LoadingContent';

const RightSectionContent = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    padding: 60px 80px;
    box-shadow: 0px 5px 15px rgba(9, 30, 66, 0.15);
    width: 700px;
    min-height: 470px;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

type Props = {
  isLoading?: boolean;
  children?: ReactNode;
};

export function PlanFinderContent(props: Props): ReactElement {
  return <RightSectionContent>{props.isLoading ? <LoadingContent /> : props.children}</RightSectionContent>;
}
