import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Theme } from '@breathelife/types';

export type ThemeState = {
  theme?: Theme;
  hasLoadingError: boolean;
};

type ThemePayload = { theme: Theme };
type ErrorPayload = { hasLoadingError: boolean };

type ActionsType = {
  setTheme: (state: ThemeState, { payload }: PayloadAction<ThemePayload>) => void;
  setHasErrorPayload: (state: ThemeState, { payload }: PayloadAction<ErrorPayload>) => void;
};

const defaultState = {
  hasLoadingError: false,
};

export const themeSlice = createSlice<ThemeState, ActionsType>({
  name: 'consumerFlow/theme',
  initialState: defaultState,
  reducers: {
    setTheme: (state: ThemeState, { payload }: PayloadAction<ThemePayload>) => {
      state.theme = payload.theme;
    },
    setHasErrorPayload: (state: ThemeState, { payload }: PayloadAction<ErrorPayload>) => {
      state.hasLoadingError = payload.hasLoadingError;
    },
  },
});
