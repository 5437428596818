import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { errorSlice, navigationSlice, progressSlice } from '@breathelife/redux';
import { Language, VersionedAnswers } from '@breathelife/types';

import { shortLocale, text } from '../../Localization/Localizer';
import { Step } from '../../Models/Step';
import Urls, { generateQuestionWithStepIdUrl } from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import * as ApplicationService from '../../Services/ApplicationService';
import { getLocalizedErrorMessage } from '../../Services/Errors/ServiceErrorCodes';
import { insuranceApplicationSlice } from '../InsuranceApplication/InsuranceApplicationSlice';
import { notificationSlice } from '../Notification/NotificationSlice';
import { ConsumerFlowStore } from '../Store';
import { stepSlice } from './StepSlice';

const stepActions = stepSlice.actions;
const progressActions = progressSlice.actions;
const errorActions = errorSlice.actions;
const notificationActions = notificationSlice.actions;
const insuranceApplicationActions = insuranceApplicationSlice.actions;

export const answerQuestionAndNavigate =
  (stepId: string, versionedAnswers: VersionedAnswers, navigate: NavigateFunction) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    const currentStepId = store().consumerFlow.step.currentStep?.id;

    if (!insuranceApplication) {
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    let nextStep = null;

    const language = shortLocale();
    try {
      const response = await ApplicationService.sendQuestionAnswer(
        insuranceApplication.id,
        stepId,
        versionedAnswers,
        language,
      );
      dispatch(errorActions.setError({ errorId: null }));

      dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

      if (response.step) {
        dispatch(progressActions.setProgress({ progress: response.progressTotal }));
        dispatch(stepActions.setHasErrors({ hasErrors: response.hasErrors ?? false }));
        nextStep = response.step;
        dispatch(stepActions.setNextStep({ step: nextStep }));
      }
    } catch (e: any) {
      const errorResponse = e.response?.data;
      const errorId = errorResponse?.message;
      const serviceErrorCode = errorResponse?.data?.serviceErrorCode;

      dispatch(errorActions.setError({ errorId }));
      dispatch(notificationActions.setError({ message: getLocalizedErrorMessage(serviceErrorCode) }));

      TypewriterTracking.stepErrored({
        error: errorId,
        hashedId: hash(insuranceApplication.id),
        stepId: currentStepId ?? '',
      });
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    if (nextStep) {
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      const url = generateQuestionWithStepIdUrl(nextStep.id);
      navigate(url);
    }
  };

export const setCurrentQuestionById =
  (stepId: string, language: Language, navigate: NavigateFunction) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    const nextStep = store().consumerFlow.step.nextStep;
    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;

    if (!insuranceApplication) {
      navigate(Urls.home.fullPath);
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchStepNoApplication') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    if (nextStep?.id === stepId) {
      // If the question's data was cached in the store by a previous operation, use this
      // data rather than making a roundtrip to the backend to get the question.
      dispatch(stepActions.setCurrentStep({ step: nextStep }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    try {
      dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));
      const response = await ApplicationService.getQuestion(insuranceApplication.id, stepId, language);
      // If this operation has been called with a different questionById while the network request was in flight,
      // skip this operation here and do not update the store.
      const loadingStepId = store().consumerFlow.step.loadingStepId;
      if (loadingStepId === stepId) {
        dispatch(stepActions.setCurrentStep({ step: response.step }));
        dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));
        dispatch(progressActions.setProgress({ progress: response.progressTotal }));
        dispatch(stepActions.setHasErrors({ hasErrors: response.hasErrors ?? false }));
      }
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
      if (e.response?.status === 404) {
        navigate(Urls.fourOhFour.fullPath);
      }
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };

export const getPreviousQuestion =
  (stepId: string, language: Language) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<Step | null> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication || !stepId) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return null;
    }

    let previousQuestion = null;

    try {
      const response = await ApplicationService.getPreviousQuestionData(insuranceApplication.id, stepId, language);

      // If a new question started fetching while the request was in-flight, cancel this operation.
      if (!response.step || store().consumerFlow.step.loadingStepId !== stepId) return null;

      previousQuestion = response.step;
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    return previousQuestion;
  };

export const navigateToPreviousQuestion =
  (stepId: string, language: Language, navigate: NavigateFunction) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    if (store().consumerFlow.navigation.loadingPage.isVisible) {
      // if the loading page is present, just hide it, but stay on the same URL
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      return;
    }

    dispatch(stepActions.setIsLoading({ isLoading: true, stepId: stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    const isLandingStep = store().consumerFlow.configuration.landingStepsIds.includes(stepId);

    // If we are on the first question, navigate back to the homepage.
    if (isLandingStep) {
      navigate(Urls.home.fullPath);
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return;
    }

    let previousStepId;

    const response = await ApplicationService.getPreviousQuestionData(insuranceApplication.id, stepId, language);

    // If a new question started fetching while the request was in-flight, cancel this operation.
    if (store().consumerFlow.step.loadingStepId !== stepId) return;
    dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

    if (response.step) {
      dispatch(stepActions.setNextStep({ step: response.step }));
      previousStepId = response.step.id;
    }

    dispatch(errorActions.setError({ errorId: null }));

    if (previousStepId) {
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      const url = generateQuestionWithStepIdUrl(previousStepId);
      navigate(url);
    } else {
      navigate(Urls.fourOhFour.fullPath);
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };

// TODO: This is almost a duplicate of getPreviousQuestion, we might be able to merge it
export const getStep =
  (stepId: string, language: Language, navigate: NavigateFunction) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<Step | null> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId }));

    const insuranceApplication = store().consumerFlow.insuranceApplication.insuranceApplication;
    if (!insuranceApplication || !stepId) {
      dispatch(notificationActions.setError({ message: text('apiErrors.retrieving') }));
      dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
      return null;
    }

    let nextStep = null;

    try {
      const response = await ApplicationService.getQuestion(insuranceApplication.id, stepId, language);

      // If a new question started fetching while the request was in-flight, cancel this operation.
      if (!response.step || store().consumerFlow.step.loadingStepId !== stepId) return null;
      dispatch(insuranceApplicationActions.setInsuranceApplication({ insuranceApplication: response.application }));

      nextStep = response.step;
      dispatch(progressActions.setProgress({ progress: response.progressTotal }));
      dispatch(stepActions.setNextStep({ step: nextStep }));
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchFirstQuestion') }));
      if (e.response.status === 404) {
        navigate(Urls.fourOhFour.fullPath);
      }
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));

    return nextStep;
  };

export const getLandingStep =
  (lang: Language) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(stepActions.setIsLoading({ isLoading: true, stepId: null }));

    try {
      const { data: response } = await ApiService.consumer.getQuestionnaireLandingStep(lang);

      dispatch(stepSlice.actions.setNodeIdToAnswerPathMap({ nodeIdToAnswerPathMap: response.nodeIdToAnswerPathMap }));
      dispatch(stepSlice.actions.setCurrentStep({ step: response.step }));
      dispatch(stepSlice.actions.setBlueprint(response.blueprint));
    } catch (error: any) {
      dispatch(notificationSlice.actions.setError({ message: text('apiErrors.fetchLandingStep') }));
    }

    dispatch(stepActions.setIsLoading({ isLoading: false, stepId: null }));
  };
