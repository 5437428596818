import queryString from 'query-string';
import { ReactElement, Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Helmet, SchedulingTimeFrameConfig } from '@breathelife/ui-components';
import { useGetCloudinaryLinkAttributes } from '@breathelife/react-hooks';

import { GenericLinkLandingContainer } from '../Components/GenericLinkLanding/GenericLinkLandingContainer';
import { Modal } from '../Components/Modals';
import { Notification } from '../Components/Notification/Notification';
import { CarrierContext } from '../Context/CarrierContext';
import { useCxSelector } from '../Hooks/useCxSelector';
import Urls from '../Navigation/Urls';
import { ContactConfirmationPageContainer } from '../Pages/ContactConfirmationPage/ContactConfirmationPageContainer';
import { CreateMockApplicationView } from '../Pages/CreateMockApplicationPage/CreateMockApplicationView';
import { ErrorPageContainer } from '../Pages/ErrorPage/ErrorPageContainer';
import { HomePageContainer } from '../Pages/HomePage/HomePageContainer';
import { SessionExpired } from '../Pages/SessionExpired/SessionExpired';
import { StepPageContainer } from '../Pages/StepPage/StepPageContainer';
import { configurationSlice } from '../Redux/Configuration/ConfigurationSlice';
import { stepSlice } from '../Redux/Step/StepSlice';
import { initializeApiServiceInterceptors, setAuthorizationInterceptor } from '../Services/ApiService';

type RootProps = {
  lastStepsIds: string[];
  enableNewApplicationRoute: boolean;
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
};

function QuestionRoute(): ReactElement | null {
  const location = useLocation();
  const search = queryString.parse(location.search);
  const stepQuery = search.question as string | undefined;
  if (!stepQuery) return null;

  return <StepPageContainer stepId={stepQuery} />;
}

export function Root(props: RootProps): ReactElement | null {
  const [hasAddedAuthHeader, setHasAddedAuthHeader] = useState(false);
  const dispatch = useDispatch();

  const { carrierInfo, cloudinaryConfig } = useContext(CarrierContext);
  const applicationSessionJwtToken = useCxSelector((store) => store.consumerFlow.authentication.token);
  const hasAppliedThroughAdvisor = useCxSelector((store) => store.consumerFlow.communication.hasAppliedThroughAdvisor);
  const applicationId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id);

  const { lastStepsIds, schedulingTimeFrames } = props;

  const linkAttributes = useGetCloudinaryLinkAttributes([
    { cloudinaryConfig, compactLogoUrl: carrierInfo.logoCompact },
  ]);

  // Now that the `StepSlice` has been blacklisted from redux-persist, this makes sure to clear the StepSlice if it still lives
  // in the localeStorage (redux-persist) on mount because the last time the user opened the application, the StepSlice was still
  // saved in localeStorage and might contains wrong information like wrong nodeId.
  useEffect(() => {
    dispatch(stepSlice.actions.reset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      configurationSlice.actions.setConfigurations({
        lastStepsIds,
        schedulingTimeFrames,
      }),
    );
    initializeApiServiceInterceptors({ [401]: Urls.expired.fullPath });
  }, [dispatch, lastStepsIds, schedulingTimeFrames]);

  useEffect(() => {
    setHasAddedAuthHeader(false);

    if (applicationSessionJwtToken) {
      setAuthorizationInterceptor(applicationSessionJwtToken);
    }
    setHasAddedAuthHeader(true);
  }, [applicationSessionJwtToken]);

  // If we don't wait for the auth header to be set, on refresh, some API calls can occur before the token is set on the api service
  // Leading api calls to fail with a 401, and redirecting users to the /expired route
  // To prevent this issue, we make sure the useEffect above has finished running before rendering the rest of the module
  if (!hasAddedAuthHeader) return null;

  const canRenderConsumerFlowRoutes = !applicationId || !hasAppliedThroughAdvisor;
  const appliedThroughAdvisorRoutes = (
    <Routes>
      <Route index element={<HomePageContainer />} />
      <Route path={Urls.contactConfirmation.path} element={<ContactConfirmationPageContainer />} />
      <Route path={Urls.fourOhFour.path} element={<ErrorPageContainer />} />
      <Route path={Urls.expired.path} element={<SessionExpired />} />
      <Route path='*' element={<Navigate to={Urls.contactConfirmation.fullPath} replace />} />
    </Routes>
  );

  const consumerFlowRoutes = (
    <Routes>
      <Route index element={<HomePageContainer />} />
      <Route path={Urls.questions.path} element={<QuestionRoute />} />
      <Route path={Urls.genericLinkLanding.path} element={<GenericLinkLandingContainer />} />
      <Route path={Urls.contactConfirmation.path} element={<ContactConfirmationPageContainer />} />
      <Route path={Urls.error.path} element={<ErrorPageContainer />} />
      {props.enableNewApplicationRoute && <Route path={Urls.new.path} element={<CreateMockApplicationView />} />}
      <Route path={Urls.fourOhFour.path} element={<ErrorPageContainer />} />
      <Route path={Urls.expired.path} element={<SessionExpired />} />
      <Route path='*' element={<Navigate to={Urls.fourOhFour.fullPath} replace />} />
    </Routes>
  );

  return (
    <Fragment>
      <Helmet
        linkAttributes={linkAttributes}
        metaAttributes={[{ content: carrierInfo.indexPageTitle, name: 'description' }]}
        titleAttributes={{ title: carrierInfo.indexPageTitle }}
      />
      <Notification />
      <Modal />
      {canRenderConsumerFlowRoutes ? consumerFlowRoutes : appliedThroughAdvisorRoutes}
    </Fragment>
  );
}
