import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Urls from '../../../Navigation/Urls';
import { communicationSlice } from '../../../Redux/Communication/CommunicationSlice';
import { ContactModalContainer } from './ContactModalContainer';

export function ApplyThroughAdvisorModalContainer(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAfterSubmit = (): void => {
    dispatch(communicationSlice.actions.setHasAppliedThroughAdvisor(true));
    navigate(Urls.contactConfirmation.fullPath);
  };
  return <ContactModalContainer useMobileStylingOnDesktop={true} onAfterSubmit={onAfterSubmit} closeOnSubmit={true} />;
}
