import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { StripeCreditCardForm, StripeFormRefHandle } from '@breathelife/payments-ui';
import { IconName } from '@breathelife/types';
import { Information, Loader, NavigationButtons, Icon } from '@breathelife/ui-components';

import { CenteredLayout } from '../../../../Layouts/Centered/Layout';
import { text } from '../../../../Localization/Localizer';
import { StyledNextButton } from '../../Styles';

type StripeViewProps = {
  isLoading?: boolean;
  isSubmitting?: boolean;
  hasSubmitted?: boolean;
  formRef: React.RefObject<StripeFormRefHandle>;
  clientSecret: string | null;
  onSubmit: () => void;
  onPreviousClick: () => void;
};

export function StripeView(props: StripeViewProps): ReactElement {
  const {
    isLoading = false,
    isSubmitting = false,
    hasSubmitted = false,
    formRef,
    clientSecret,
    onSubmit,
    onPreviousClick,
  } = props;

  return (
    <CenteredLayout
      title={text('payment.title')}
      subtitle={text('payment.subtitle')}
      offsetContent={<Icon name={IconName.payment} />}
      isLoading={isLoading}
      loadingContent={{
        title: '',
        subtitle: '',
      }}
    >
      <Box mt={2}>
        {clientSecret ? (
          <form onSubmit={onSubmit} noValidate={true}>
            <StripeCreditCardForm clientSecret={clientSecret} disabled={isSubmitting || hasSubmitted} ref={formRef} />
          </form>
        ) : null}
      </Box>
      <Box mt={3}>
        <Information text={text('payment.informationText')} />
      </Box>
      <Box>
        <NavigationButtons
          onPreviousClick={onPreviousClick}
          hidePrevious={false}
          hideNext={false}
          nextButton={
            <StyledNextButton onClick={onSubmit}>
              {isSubmitting && <Loader color='white' />}
              {!isSubmitting && !hasSubmitted && text('payment.continueButtonText')}
              {!isSubmitting && hasSubmitted && (
                <Icon name={IconName.confirmationOutlined} color={{ primary: 'white' }} />
              )}
            </StyledNextButton>
          }
          onNextClick={onSubmit}
        />
      </Box>
    </CenteredLayout>
  );
}
