import { Dispatch } from 'redux';

import { authenticationSlice } from '@breathelife/redux';

import { insuranceApplicationSlice } from './InsuranceApplication/InsuranceApplicationSlice';
import { stepSlice } from './Step/StepSlice';
import { submissionSlice } from './Submission/SubmissionSlice';

export const resetApplication =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(authenticationSlice.actions.reset());
    dispatch(insuranceApplicationSlice.actions.reset());
    dispatch(stepSlice.actions.reset());
    dispatch(submissionSlice.actions.reset());
  };
