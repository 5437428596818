import { Box } from '@breathelife/mui';
import styled from 'styled-components';

import { Title } from '@breathelife/ui-components';
import { Button } from '../Button';

export const Card = styled(Box)`
  min-height: calc(100vh - 80px);
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow-y: scroll;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    position: inherit;
    height: 100%;
    flex-direction: column;
  }
`;

export const CardContent = styled(Box)`
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    margin-left: 0;
  }
`;

export const Content = styled(Box)`
  margin: 50px 0;
  padding: 30px 30px 10px 30px;
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    border-radius: 0;
    height: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
type MainImageProps = {
  $image: string;
  $displayMobile?: boolean;
};
export const MainImage = styled(Box)<MainImageProps>`
  background-image: ${(props) => `url(${props.$image})`};
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    height: 375px;
    position: inherit;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    display: ${(props) => (!props.$displayMobile ? 'none' : 'block')};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop.min}) and (orientation: landscape) {
    display: ${(props) => (!props.$displayMobile ? 'none' : 'block')};
  }
`;

export const CardTitle = styled(Title)`
  padding-bottom: 10px;
  color: ${(props) => props.theme.colors.primary.default};
`;

export const CardSubtitle = styled(Title)`
  padding-bottom: 40px;
  font-weight: 400;
`;

export const StartButton = styled(Button)`
  &&&& {
    border: none;
    margin: 30px 0;
    width: 100%;
    background-color: ${(props) => props.theme.colors.primary.default};
    color: ${(props) => props.theme.colors.grey[0]};
    min-height: 54px;
    font-size: 14px;

    span {
      transform: unset;
      text-transform: none;
    }

    &:focus {
      &:not(:disabled) {
        background-color: ${(props) => props.theme.colors.button.base.background.hover};
      }

      &:not(:active) {
        outline: 3px solid ${(props) => props.theme.colors.button.base.border.focus};
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primary[60]};
    }
  }
`;
