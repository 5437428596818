export default {
  header: {
    title: 'Personalized insurance contract',
    subtitle: 'for',
    referenceNumber: 'Reference number',
  },
  yesNoAnswer: {
    true: 'yes',
    false: 'no',
  },
  bank: {
    transit: 'Transit',
    branch: 'Institution',
    account: 'Account number',
  },
  heightWeight: {
    height: 'Height in inches',
    weight: 'Weight in pounds',
    feetUnit: 'ft.',
    inchesUnit: 'in.',
    weightUnit: 'lbs',
  },
  personalInfo: {
    title: 'Personal information',
    insured: 'Insured person',
    name: 'Full name',
    gender: 'Gender',
    address: 'Address',
    email: 'Email',
    phone: 'Phone number',
    contractOwner: 'Contract owner',
    payer: 'Payer',
    smoker: 'Smoker',
    nonSmoker: 'Non-smoker',
  },
};
