// React-Router (v6+) now uses two types of paths. An absolute path to redirect, and a relative path to defined nested routes.
type BaseUrls = {
  fullPath: string; // Full paths (absolute) are used to redirect using <Navigate /> component
  path: string; // Paths (relative) are used to defined the nested routes for the consumer-flow with <Routes /> component
};

const Urls: Record<string, BaseUrls> = {
  home: {
    fullPath: '/plan',
    path: '/plan',
  },
  questions: {
    fullPath: '/plan/questions',
    path: '/questions',
  },
  genericLinkLanding: {
    fullPath: '/plan/start',
    path: '/start',
  },
  contactConfirmation: {
    fullPath: '/plan/contact-confirmation',
    path: '/contact-confirmation',
  },
  fourOhFour: {
    fullPath: '/plan/404',
    path: '/404',
  },
  error: {
    fullPath: '/plan/error',
    path: '/error',
  },
  new: {
    fullPath: '/plan/new',
    path: '/new',
  },
  expired: {
    fullPath: '/plan/expired',
    path: '/expired',
  },
};

export function generateQuestionWithStepIdUrl(stepId: string): string {
  return `${Urls.questions.fullPath}?question=${stepId}`;
}

export default Urls;
