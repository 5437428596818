import _ from 'lodash';
import { ReactNode, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  AdvisorInfoPanel,
  HeaderContent,
  HeaderProps,
  PlanFinderHeader as PlanFinderSharedHeader,
  ProgressHeader as BaseProgressHeader,
  ResponsiveProps,
  withResponsive,
} from '@breathelife/ui-components';

import { ScheduleCallButtonContainer } from '../../Components/ScheduleCallButton/ScheduleCallButtonContainer';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import { useGetAdvisorTitleFallback } from '../../Hooks/useGetAdvisorTitleFallback';

const StyledHeaderContent = styled(PlanFinderSharedHeader)`
  > ${HeaderContent} {
    height: 100px;
    padding: 24px;
    background-color: white;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding: 32px;
      background: transparent;
    }
  }
`;

const MobileContent = styled.div<ParallaxProps>`
  padding-top: 100px;
  ${(props) => props.parallax && `z-index: -99;`}
`;

type ParallaxProps = {
  parallax?: boolean;
};

type Props = {
  children?: ReactNode;
} & ResponsiveProps &
  HeaderProps &
  ParallaxProps;

function BaseHeader(props: Props): ReactElement {
  const scrollContentRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const assignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const advisorTitleFallback = useGetAdvisorTitleFallback();

  useEffect(() => {
    // This is to calculate the header status based on the initial scroll on the page.
    // setTimeout is required otherwise this is calculated before the browser initial scroll happens
    setTimeout(() => {
      const pageScrolled = window.scrollY > _.get(scrollContentRef, 'current.offsetHeight');

      if (!isFixed && pageScrolled) {
        setIsFixed(true);
      }
    }, 0);
  }, [isFixed]);

  let leftContent = props.leftContent;

  if (!leftContent && assignee) {
    leftContent = (
      <AdvisorInfoPanel
        advisorInfo={{
          name: assignee.name ?? `${assignee.firstName} ${assignee.lastName}`,
          title: assignee.subgroup?.name || assignee.group?.name || advisorTitleFallback,
        }}
      />
    );
  }

  return (
    <StyledHeaderContent
      leftContent={leftContent}
      rightContent={<ScheduleCallButtonContainer />}
      mobileContent={
        props.isMobile && (
          <MobileContent ref={scrollContentRef} parallax={props.parallax}>
            {props.mobileContent}
          </MobileContent>
        )
      }
    >
      {props.children}
    </StyledHeaderContent>
  );
}

export const PlanFinderHeader = withResponsive(BaseHeader);

type ProgressHeaderProps = {
  progress: number;
} & HeaderProps &
  ParallaxProps;

export function ProgressHeader(props: ProgressHeaderProps): ReactElement {
  const progressText = text('header.completion', { progress: props.progress });

  return <BaseProgressHeader headerComponent={PlanFinderHeader} progressText={progressText} {...props} />;
}
