import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import config from '@breathelife/config/frontend';

import { useCxSelector } from '../../Hooks/useCxSelector';
import Urls from '../../Navigation/Urls';
import { ContactConfirmationPage } from './ContactConfirmationPage';

export function ContactConfirmationPageContainer(): ReactElement {
  const navigate = useNavigate();

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const hasAppliedThroughAdvisor = useCxSelector((store) => store.consumerFlow.communication.hasAppliedThroughAdvisor);
  const { cloudName, folder } = config.get<Record<string, string>>('keys.cloudinary');

  useEffect(() => {
    if (!hasAppliedThroughAdvisor) {
      navigate(Urls.fourOhFour.fullPath);
    }
  }, [navigate, hasAppliedThroughAdvisor]);

  return (
    <ContactConfirmationPage
      applicationAssignee={applicationAssignee}
      cloudinaryProps={{
        cloudName,
        folder,
      }}
    />
  );
}
