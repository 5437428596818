export default {
  header: {
    title: 'Programme d’assurance personnalisé',
    subtitle: 'pour',
    referenceNumber: 'Numéro de référence',
  },
  yesNoAnswer: {
    true: 'oui',
    false: 'non',
  },
  bank: {
    transit: 'Transit',
    branch: 'Institution',
    account: 'No. compte',
  },
  heightWeight: {
    height: 'Grandeur en pouces',
    weight: 'Poids en livres',
    feetUnit: 'pi.',
    inchesUnit: 'po.',
    weightUnit: 'lbs',
  },
  personalInfo: {
    title: 'Renseignements personnels',
    insured: 'Personne assurée',
    name: 'Prénom et nom',
    gender: 'Sexe',
    address: 'Adresse',
    email: 'Courriel',
    phone: 'Tél',
    contractOwner: 'Propriétaire du contrat',
    payer: 'Payeur',
    smoker: 'Fumeur',
    nonSmoker: 'Non fumeur',
  },
};
