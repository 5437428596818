export default {
  advisorGreeting: 'Bonjour, je suis {{advisorName}}. ',
  interlocutorGreeting: 'Bonjour, nous sommes {{interlocutorName}}',
  startFlow: 'Allons-y!',
  advisorTitle: 'Conseiller financier',
  life: {
    welcomeTextTitle: "Trouvons le plan d'assurance vie qui vous convient",
    welcomeTextSubtitle: {
      single:
        "Prenons les prochaines minutes pour répondre ensemble à quelques questions afin que je puisse vous proposer la bonne protection financière pour la tranquillité d'esprit de votre famille.",
      plural:
        "Prenons les prochaines minutes pour répondre ensemble à quelques questions afin que nous puissions vous proposer la bonne protection financière pour la tranquillité d'esprit de votre famille.",
    },
  },
};
