import { LocalizedInsuranceProduct } from '@breathelife/types';

import { LegacyProductInfo } from '../Models/Product';

type QuotedProduct = LegacyProductInfo | LocalizedInsuranceProduct;

function isLocalizedInsuranceProduct(product: QuotedProduct): product is LocalizedInsuranceProduct {
  return !!(product as LocalizedInsuranceProduct).createdAt;
}

export const sortQuotedProducts = (productA: QuotedProduct, productB: QuotedProduct): number => {
  const firstIndex = (isLocalizedInsuranceProduct(productA) && productA['index']) || Number.MAX_VALUE;
  const secondIndex = (isLocalizedInsuranceProduct(productB) && productB['index']) || Number.MAX_VALUE;

  const indexDelta = firstIndex - secondIndex;

  if (indexDelta !== 0) {
    return indexDelta;
  }

  if (isLocalizedInsuranceProduct(productA) && isLocalizedInsuranceProduct(productB)) {
    let productACreatedAt: string | Date = productA.createdAt;
    let productBCreatedAt: string | Date = productB.createdAt;

    if (typeof productACreatedAt === 'string') {
      productACreatedAt = new Date(productACreatedAt);
    }

    if (typeof productBCreatedAt === 'string') {
      productBCreatedAt = new Date(productBCreatedAt);
    }

    return productACreatedAt.getTime() - productBCreatedAt.getTime();
  }

  return 0;
};
