import { Language, LeadMarketingMetadata, VersionedAnswers } from '@breathelife/types';

import ApiService from './ApiService';
import { QuestionResponse } from './ApplicationService';

const consumerApiService = ApiService.consumer;

export async function submitQuestionnaireLandingStep(
  answers: VersionedAnswers,
  stepId: string,
  lang: Language,
  marketingMetadata?: LeadMarketingMetadata,
): Promise<QuestionResponse> {
  const applicationResponse = await consumerApiService.postQuestionnaireLandingStep(
    answers,
    stepId,
    lang,
    marketingMetadata,
  );
  return applicationResponse.data;
}
