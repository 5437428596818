import queryString from 'query-string';
import { ReactElement, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import config from '@breathelife/config/frontend';
import { ScheduleCallButton } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import { ModalType } from '../../Models/Layout';
import { fetchApplicationByToken } from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { layoutSlice } from '../../Redux/Layout/LayoutSlice';

export function ScheduleCallButtonContainer(): ReactElement | null {
  const { cloudName, folder } = config.get<{ cloudName: string; folder: string }>('keys.cloudinary');
  const dispatch = useDispatch();

  const { carrierInfo } = useContext(CarrierContext);
  const location = useLocation();

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const appId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id);
  const { token, method } = queryString.parse(location.search) as Record<string, string | undefined>;
  const isPrivateLinkMethod = method === AccessTokenMethod.privateLink || method === AccessTokenMethod.email;

  const onClick = useCallback(() => {
    if (!appId && token && isPrivateLinkMethod) {
      dispatch(fetchApplicationByToken(token));
    }
    dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: true, type: ModalType.contact } }));
  }, [dispatch, appId, token, isPrivateLinkMethod]);

  return (
    <ScheduleCallButton
      assigneeImageUrl={applicationAssignee?.picture || carrierInfo.logoCompact}
      onClick={onClick}
      buttonText={text('needToTalkButton')}
      cloudinary={{
        cloudName,
        folder,
      }}
    />
  );
}
