export default {
  title: 'Payment',
  subtitle: 'Please enter your credit card information.',
  informationText:
    'By clicking "Pay" you agree to the processing of your credit card info.<br /><br />Your credit card will be charged for the first premium right now then monthly recurring payment of subsequent premiums.',
  continueButtonText: 'Pay',
  loadFormError: 'Error while loading the payment form.',
  submitPaymentError: 'Error while submitting the payment information.',
  savePaymentError: 'Error while saving the payment information.',
};
