import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { ScreenName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { OnSubmitScheduleDataType } from '@breathelife/ui-components';

import { CarrierContext } from '../../../Context/CarrierContext';
import { assigneeIsInterlocutor } from '../../../Helpers/interlocutorHelper';
import { useCxSelector } from '../../../Hooks/useCxSelector';
import * as communicationOperations from '../../../Redux/Communication/CommunicationOperations';
import { layoutSlice } from '../../../Redux/Layout/LayoutSlice';
import { ContactModal } from './ContactModal';

type Props = {
  useMobileStylingOnDesktop?: boolean;
  closeOnSubmit?: boolean;
  onAfterSubmit?: () => void | Promise<void>;
};

export function ContactModalContainer(props: Props): ReactElement | null {
  const { useMobileStylingOnDesktop, onAfterSubmit, closeOnSubmit } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { interlocutorIds } = useContext(CarrierContext);

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);
  const isInterlocutor = assigneeIsInterlocutor(interlocutorIds, applicationAssignee);

  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);
  const applicationId = insuranceApplication?.id ?? null;

  const communication = useCxSelector((store) => store.consumerFlow.communication);
  const schedulingTimeFrameConfig = useCxSelector((store) => store.consumerFlow.configuration.schedulingTimeFrames);

  useEffect(() => {
    TypewriterTracking.viewedScreen({
      screenName: ScreenName.scheduleACallForm,
      hashedId: hash(applicationId),
    });
  }, [applicationId]);

  useEffect(() => {
    if (!insuranceApplication) return;

    dispatch(communicationOperations.getCommunicationPreferences(insuranceApplication.id));
  }, [dispatch, insuranceApplication]);

  const onCloseModal = useCallback(() => {
    dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: false } }));
  }, [dispatch]);

  const onSubmitSchedule = useCallback(
    async (scheduleCallPreferences: OnSubmitScheduleDataType) => {
      try {
        await dispatch(
          communicationOperations.setCommunicationPreferences(insuranceApplication?.id || '', scheduleCallPreferences),
        );
      } catch (err: any) {
        return;
      }
      setIsSubmitted(true);
      if (closeOnSubmit) onCloseModal();
      if (onAfterSubmit) await onAfterSubmit();
    },
    [dispatch, insuranceApplication, onAfterSubmit, closeOnSubmit, onCloseModal],
  );

  return (
    <ContactModal
      useMobileStylingOnDesktop={useMobileStylingOnDesktop}
      assignee={{ ...applicationAssignee }}
      communication={communication}
      onSubmit={onSubmitSchedule}
      isSubmitted={isSubmitted}
      referenceNumber={insuranceApplication?.refNo}
      onClose={onCloseModal}
      schedulingTimeFrameConfig={schedulingTimeFrameConfig}
      isPlural={isInterlocutor}
    />
  );
}
