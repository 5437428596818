export enum ModalType {
  contact = 'contact',
  applyThroughAdvisor = 'applyThroughAdvisor',
  continueLater = 'continueLater',
}

export type ModalState = {
  isOpen: boolean;
  type?: ModalType;
};
