import { Box } from '@breathelife/mui';
import styled from 'styled-components';

import { Text } from '@breathelife/ui-components';

export const ConfirmationBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.grey[40]};
  border-radius: 4px;
`;

export const SignatureText = styled(Text)`
  & {
    overflow-wrap: anywhere;
  }
`;
