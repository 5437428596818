export default {
  dateInvalid: 'Invalid date',
  enterValidEmail: 'Please enter a valid email address',
  fieldRequired: 'Field required',
  nameFormat: 'Please enter a valid name',
  maxDigits: 'Maximum {{length}} characters',
  zipCodeInvalid: 'Please enter a a valid zip code\n(e.g 12345 or 12345-1234)',
  enterValidNumber: 'Please enter a valid number',
  dateCannotBeFuture: 'Date cannot be in the future',
  enterFirstName: 'Please enter your first name',
  enterLastName: 'Please enter your last name',
  enterStreetAddress: 'Please enter your street address',
  enterStreetName: 'Please enter your street name',
  enterCity: 'Please enter your city',
  enterProvince: 'Please select a province',
  enterPostalCode: 'Please enter a valid postal code',
  enterValidPhoneNumber: 'Please enter a valid phone number\n(e.g: 123-456-7890)',
  enterDateInFormat: 'Please enter your date of birth in the format {{format}}',
  invalidDateOfBirth: 'Please verify the year of birth',
  ageValidations:
    'To subscribe to term life insurance online you must be 18-49 years old. To better meet your needs, [please schedule a call with us.](?schedule-call=true)',
};
