export default {
  title: 'Paiement',
  subtitle: 'Veuillez saisir les informations de votre carte de crédit.',
  informationText:
    'En cliquant sur "Payer", vous acceptez le traitement des informations de votre carte de crédit.<br /><br />Votre carte de crédit sera débitée de la première prime en ce moment, puis du paiement mensuel récurrent des primes suivantes.',
  continueButtonText: 'Payer',
  loadFormError: "Une erreur s'est produite lors du chargement du formulaire de paiement.",
  submitPaymentError: "Une erreur s'est produite lors de la soumission des informations de paiement.",
  savePaymentError: "Une erreur s'est produite lors de la sauvegarde des informations de paiement",
};
