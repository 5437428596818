import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { AddonCheckboxState, AddonsOptionUpsell, Language } from '@breathelife/types';
import { SharedTheme, Title } from '@breathelife/ui-components';

import { localize, text, toCurrency } from '../../Localization/Localizer';
import {
  Checkbox,
  CheckboxContainer,
  InformationLink,
  LargeContent,
  MonthText,
  PricingNumberText,
  PricingSelection,
  Separator,
  Subtitle,
} from './Styles';

type Props = {
  language: Language;
  addon: AddonsOptionUpsell;
  checkedAddons: AddonCheckboxState;
  onAddonChange: (value: string) => void;
};

export function SelectableAddonView(props: Props): ReactElement | null {
  const { addon, language, checkedAddons, onAddonChange } = props;

  const theme = useTheme() as SharedTheme;

  return (
    <LargeContent key={addon.id}>
      <Title level={3} color={theme.colors.grey[0]}>
        {localize(addon.title, language)}
      </Title>
      <Subtitle>{localize(addon.subtitle, language)}</Subtitle>
      <InformationLink href={addon.link} target='_blank' rel='noreferrer noopener'>
        {text('product.addons.forMoreInformation')}
      </InformationLink>
      <Separator />
      <PricingSelection>
        <PricingNumberText color={theme.colors.grey[10]}>
          {toCurrency(addon.price as number | null)}
          <MonthText>{text('product.perMonth')}</MonthText>
        </PricingNumberText>
        <CheckboxContainer>
          <Checkbox
            id={addon.id}
            checked={checkedAddons[addon.id]}
            onChange={() => {
              onAddonChange(addon.id);
            }}
            label={text('product.addons.purchasableOption')}
          />
        </CheckboxContainer>
      </PricingSelection>
    </LargeContent>
  );
}
